import React, { Component, Fragment } from 'react';
import TmDateTimePicker from '../../utilComponents/tmDateTimePicker/tmDateTimePicker';
import ReactSelect from '../../../components/utilComponents/tmSlimSelect/reactSelect';
import { GlobalValues } from '../../../util/constants.js';
import moment from 'moment';
import Form from 'react-validation/build/form';
import {
    required
} from "../../../util/validationMethods.js";
import { ExcelFile, ExcelSheet, ExcelColumn } from 'react-data-export';
import { isManagement } from '../../../util/customMethods';
class PaymentAuditCtrl extends Component {
    render() {
        let { stateObj } = this.props;
        return (
            <Fragment>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card form-group">
                            <div className="card-body pd-10">
                                <Form ref={c => {
                                    this.formPaymentAuditInfo = c;
                                    this.props.initCallBack(c);
                                }}>
                                    <div className="form-row">
                                        <div className="col-1 form-group">
                                            <label htmlFor="inlineOriginalPaymentDate" className="">USER ID</label>
                                        </div>
                                        <div className="col-2 form-group">
                                            <ReactSelect name="idCompany"
                                                isClearable={true}
                                                closeMenuOnSelect={true}
                                                notAmultiSelect={true}
                                                options={(this.props.allInvoiceObj.driverList || []).map((item, t) => {
                                                    return ({ value: item.driverID, label: item.driverID })
                                                })}
                                                value={{
                                                    value: ((stateObj.userId || '').toString()),
                                                    label: ((this.props.allInvoiceObj.driverList || []).find((e) => e.driverID == (stateObj.userId || '').toString()) || {}).driverID
                                                }}
                                                onChange={(selectedOption) => {
                                                    this.props.onPaymentAuditFieldChange('userId', selectedOption && (selectedOption.value)|| '');
                                                }}
                                            />
                                        </div>
                                        {isManagement() ? <div className="col-1 form-group">
                                            <label htmlFor="inlineOriginalPaymentDate" className="">COMPANY</label>
                                        </div> : null}
                                        {isManagement() ? <div className="col-2 form-group">
                                            <ReactSelect name="idCompany"
                                                closeMenuOnSelect={true}
                                                notAmultiSelect={true}
                                                isClearable={true}
                                                options={(this.props.childCompList || []).map((item, t) => {
                                                    return ({ value: item.optionVal, label: item.optionText })
                                                })}
                                                value={{
                                                    value: ((stateObj.idCompany || '').toString()),
                                                    label: ((this.props.childCompList || []).find((e) => e.optionVal == (stateObj.idCompany || '').toString()) || {}).optionText
                                                }}
                                                onChange={(selectedOption) => {
                                                    this.props.onPaymentAuditFieldChange('idCompany', parseInt(selectedOption && selectedOption.value));
                                                }}
                                            />
                                        </div> : null}

                                        <div className="col-6 form-group">
                                            <div className="form-group row">
                                                <div className="col">
                                                    <div className="custom-control custom-control custom-radio">
                                                        <input checked={stateObj.payLevel == 1} type="radio" className="custom-control-input" onChange={() => { this.props.onPaymentAuditFieldChange('payLevel', 1) }}
                                                            name="payDate" id="inlineOriginalPaymentDate" />
                                                        <label htmlFor="inlineOriginalPaymentDate" className="custom-control-label">ORI. PAYMENT</label>
                                                    </div>
                                                </div>

                                                <div className="col">
                                                    <div className="custom-control custom-control custom-radio">
                                                        <input checked={stateObj.payLevel == 2} type="radio" onChange={() => { this.props.onPaymentAuditFieldChange('payLevel', 2) }}
                                                            className="custom-control-input" name="payDate" id="inlineNewPaymentDate" />
                                                        <label htmlFor="inlineNewPaymentDate" className="custom-control-label">NEW PAYMENT</label>
                                                    </div>
                                                </div>

                                                <div className="col">
                                                    <div className="custom-control custom-control custom-radio">
                                                        <input checked={stateObj.payLevel == 3} type="radio" onChange={() => { this.props.onPaymentAuditFieldChange('payLevel', 3) }}
                                                            className="custom-control-input" name="payDate" id="inlineChangedOnDate" />
                                                        <label htmlFor="inlineChangedOnDate" className="custom-control-label">CHANGED ON</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-1 form-group">
                                            <label htmlFor="from" className="form-label col-form-label">FROM</label>
                                        </div>
                                        <div className="col-2 form-group">
                                            <div>
                                                <TmDateTimePicker
                                                    className="form-control"
                                                    selectedDate={
                                                        this.props.fromdate === "" || this.props.fromdate === null
                                                            ? null
                                                            : moment(
                                                                this.props.fromdate,
                                                                GlobalValues.dateFormat
                                                            )._d
                                                    }
                                                    dateFormat={GlobalValues.dateFormat}
                                                    validationArray={[required]}
                                                    showYearDropdown={true}
                                                    showmonthdropdown={true}
                                                    name="fromDate"
                                                    reqmsg="From date is required"
                                                    onDateSelectChange={e => {
                                                        this.props.onPaymentAuditFieldChange('fromDate', e === null ? '' : moment(e).format(GlobalValues.dateFormat))
                                                        this.forceUpdate()
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-1 form-group">
                                        </div>
                                        <div className="col-1 form-group">
                                            <label htmlFor="to" className="form-label col-form-label">TO</label>
                                        </div>
                                        <div className="col-2 form-group">
                                            <div>
                                                <TmDateTimePicker
                                                    className="form-control"
                                                    selectedDate={
                                                        this.props.todate === "" || this.props.todate === null
                                                            ? null
                                                            : moment(
                                                                this.props.todate,
                                                                GlobalValues.dateFormat
                                                            )._d
                                                    }
                                                    dateFormat={GlobalValues.dateFormat}
                                                    validationArray={[required]}
                                                    showYearDropdown={true}
                                                    showmonthdropdown={true}
                                                    name="toDate"
                                                    reqmsg="To date is required"
                                                    onDateSelectChange={e => {
                                                        this.props.onPaymentAuditFieldChange('toDate', e === null ? '' : moment(e).format(GlobalValues.dateFormat))
                                                        this.forceUpdate()
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-5 form-group">
                                            <button type="button" className="btn btn-success custom-reports-btn mr-2"
                                                onClick={this.props.Go}>Go</button>
                                            <button type="button" onClick={this.props.Export} className="btn btn-warning custom-reports-btn mr-2" >Export</button>
                                            {this.props.stateObj.render ?
                                                <ExcelFile hideElement={true}
                                                    filename={`${"PaymentAuditReport"}${moment().format('MMDDYYhhmm')}`}
                                                    fileExtension={'xlsx'}
                                                >
                                                    <ExcelSheet
                                                        data={this.props.data || []}
                                                        name={'Response Time Report'}
                                                    >
                                                        {(this.props.cols || []).map(column => {
                                                            return (
                                                                <ExcelColumn
                                                                    key={column.accessor}
                                                                    label={column.Header}
                                                                    value={column.accessor}
                                                                />
                                                            );
                                                        })}
                                                    </ExcelSheet>
                                                </ExcelFile>
                                                : null}
                                            <button type="button" className="btn btn-primary custom-reports-btn mr-2" onClick={this.props.Email} >Email</button>
                                            <button type="button" className="btn btn-success custom-reports-btn mr-2" onClick={this.props.Print}>Print</button>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}
export default PaymentAuditCtrl;