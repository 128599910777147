import React, { Fragment } from 'react';
import Input from 'react-validation/build/input';
import Form from 'react-validation/build/form';
import { Modal } from 'react-bootstrap';
import Select from 'react-validation/build/select';
import moment from 'moment';
import {
    required,
    isValidForm,
    NotZero
} from "../../../util/validationMethods";
import { isManagement } from '../../../util/customMethods'
import { paymentState } from '../../../util/stateUtil/initialStateForComponents'
import TmReactTable from '../../utilComponents/tmReactTable/tmReactTable';
let paymentForm = {};
class Payment extends React.Component {
    constructor(props) {
        super();
        this.state = {
        ...paymentState,
        addPaymentApprovalModal: false,
        locationList: '',
        paymentApprovalLocation: '',
    }
    console.log("props:::::::::",props.locationList);
    }
    handlePaymentLocationChange=(event)=> {
        this.setState({ paymentApprovalLocation: event.target.value });
      }

    handlePayment = (event) => {
        let obj = this.state;
        obj[event.target.name] = event.target.value;
        this.setState({ obj });
    }

    initCallBack = (c) => {
        this.setState({ paymentForm: c })
    }

    setPaymentToEmpty = (obj) => {
        this.setState({
            cctxNo: this.state.cctxNo,
            chequeNo: (obj||undefined)==undefined?"":obj.chequeNo,
            invoiceId: (obj||undefined)==undefined?"":obj.invoiceId,
            paymentAmt: (obj||undefined)==undefined?0:obj.paymentAmt,
            paymentDate: (obj||undefined)==undefined?"":obj.paymentDate,
            paymentId: (obj||undefined)==undefined?0:obj.paymentId,
            paymentLocation: (obj||undefined)==undefined?"":obj.paymentLocation,
            paymentType: (obj||undefined)==undefined?"":obj.paymentType,
            transactionNo: (obj||undefined)==undefined?"":obj.transactionNo,
            userId:(obj||undefined)==undefined?"":obj.userId,
            isPending:(obj||undefined)==undefined?"":obj.isPending,
        })
    }

    addPayment = (obj) => {
        paymentForm.validateAll();
        if (!isValidForm(paymentForm))
            return false;
        this.props.addUpdatePayments(this.props.invoiceId, {
            cctxNo: this.state.cctxNo,
            chequeNo: this.state.chequeNo,
            invoiceId: this.state.invoiceId,
            paymentAmt: this.state.paymentAmt,
            paymentDate: this.state.paymentDate || moment().format('YYYY-MM-DD HH:mm:ss'),
            paymentId: this.state.paymentId,
            paymentLocation: this.state.paymentLocation,
            paymentType: this.state.paymentType,
            transactionNo: this.state.transactionNo,
            userId: this.state.userId,
            isPending: this.state.isPending,
        });
        this.setState({ editPaymentModel: false, addPaymentApprovalModal: false });
        this.setPaymentToEmpty();
    }
    render() {
        const isDisabled = this.props.isDisabled;
        const columns = [{
            accessor: 'userId',
            style: { textAlign: 'center' },
            Header: 'USER ID'
        },
        {
            accessor: 'paymentDate',
            style: { textAlign: 'center' },
            Header: 'DATE'
        },
        // {
        //     accessor: 'paymentLocation',
        //     style: { textAlign: 'center' },
        //     Header: 'PAYMENT LOCATION',
        //     show:!isManagement()
        // },
        {

            accessor: 'paymentType',
            style: { textAlign: 'center' },
            Header: 'PAYMENT TYPE'
        },
        {
            accessor: 'paymentAmt',
            style: { textAlign: 'center' },
            Header: 'AMOUNT'
        },
        {
            accessor: 'isPending',
            Header: 'STATUS',
            style: { textAlign: 'center' },
            className: 'color-blue-grey-lighter',
            Cell: props => <div>{props.value ? "Pending" : "Approved"} </div>
        },
        {
            accessor: 'Actions',
            Header: 'Actions',
            sortable: false,
            filterable: false,
            style: { textAlign: 'center' },
            Cell: (row) => {
                return <div>
                     {(this.props.paymentAccess.edit && !this.state.updatePayment && !row.original.isPending) ? (
                    <input type="button" disabled={(!this.props.paymentAccess.edit) || isDisabled} className="btn btn-sm btn-primary mr-10" value="Edit" onClick={() => {
                        this.setPaymentToEmpty(row.original)
                        this.setState({ editPaymentModel: true });
                    }} />):null}
                    {(this.props.paymentAccess.delete && !row.original.isPending) ? ( <input disabled={(!this.props.paymentAccess.delete) || isDisabled} type="button" className="btn btn-sm btn-danger" value="Delete"
                        onClick={() => {
                            this.setState({ deleteModel: true, paymentId: row.original.paymentId })
                        }} />): null}&nbsp;&nbsp;
                    {/* {this.props.paymentAccess.add && row.original.isPending ? ( <input disabled={(!this.props.paymentAccess.add) || isDisabled} type="button" className="btn btn-sm btn-success" value="Add"
                        onClick={() => {
                            this.setState({ addPaymentApprovalModal: true, paymentId: row.original.paymentId })
                     }} /> ):null} */}
                     {this.props.paymentAccess.add && row.original.isPending ? (
                      <button
                       disabled={!this.props.paymentAccess.add || isDisabled}
                       type="button"
                       className="btn btn-sm btn-success"
                       onClick={() => {
                       this.setState({
                        addPaymentApprovalModal: true,
                        paymentId: row.original.paymentId,
                        });
                        }}
                       > <i className="fa fa-check" aria-hidden="true"></i> Add
                   </button>
                   ) : null}
                </div>;
            }
        }
    ];
     
        return (<Fragment>
            <Form
                ref={c => {
                    paymentForm = c;
                }}
            >
                <div className="pd-10">
                    <h6 className="text-blue text-bg-color form-group">Payments</h6>
                    <div className="text-right">
                                    <input type="button" disabled={isDisabled} className="btn btn-sm btn-success" value={"Add Payment"} onClick={() => {
                                       this.setPaymentToEmpty(undefined)
                                       this.setState({ editPaymentModel: true });
                                    }} /></div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12 mb-10">
                            <TmReactTable
                                filterable
                                sortable
                                resizable
                                defaultPageSize={25}
                                data={this.props.invoicePayments}
                                columns={columns}
                            />
                        </div>
                    </div>
                    <div className="row">
                      
                    {/*  {!isManagement()?
                    <div className="col-md-3">
                        <label htmlFor="accountName" className="form-label col-form-label">PAYMENT LOCATION</label>
                        <Select className="form-control" name="paymentLocation" onChange={(event)=>{this.handlePayment(event)}} disabled={isDisabled} value={this.state.paymentLocation}
                        validations={isManagement()?[]:[required]} requiredmsg="Please Select Payment Location." >
                            <option value="">Select Location</option>
                            {
                                this.props.locationList[0].length > 0 && this.props.locationList[0].filter(loc => loc.paymentLocation === 1).map((item, i) => {
                                   
                                    return (
                                        <option key={i} value={item.location}>{item.location}</option>
                                    )   
                                })
                            }
                        </Select>
                    </div> : null*/}
                        {/* <div className="col-md-4">
                            <label htmlFor="accountName" className="form-label col-form-label">PAYMENT TYPE</label>
                            <Select className="form-control mb-10" onChange={(event) => { this.handlePayment(event) }}
                                validations={[required]} requiredmsg="Please Select Payment Type." disabled={isDisabled} name="paymentType"
                                value={this.state.paymentType} >
                                <option value=""> Payment Type</option>
                                <option value="Cash">Cash</option>
                                <option value="Check">Check</option>
                                <option value="EmployeeAdvance">Employee Advance</option>
                                <option value="Transfer">Transfer</option>
                            </Select> {
                                this.state.paymentType === 'Check' ?
                                    <input placeholder="Check #" type="text" name="chequeNo" onChange={(event) => { this.handlePayment(event) }} disabled={isDisabled}
                                        className="form-control" value={this.state.chequeNo} /> : null
                            }
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="accountName" className="form-label col-form-label">AMOUNT</label>
                            <div className="input-groups">
                                <div class="input-groups-icon">$</div>
                                <div class="input-groups-area">
                                    <Input type="number" validations={[required, NotZero]} requiredmsg="Please Enter Payment Amount." disabled={isDisabled} onChange={(event) => { this.handlePayment(event) }} className="form-control form-control-sm"
                                        name="paymentAmt" value={this.state.paymentAmt} />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <label>&nbsp;</label>
                            <div className="">
                                <input type="button" disabled={(!this.props.paymentAccess.add) || isDisabled} className="btn btn-sm btn-success" value="Add Payment" onClick={() => {
                                    this.addPayment(this.state.paymentObj);
                                    this.setState({ paymentObj: this.state.defaultObj })
                                }} />
                            </div>
                        </div> */}
                    </div></div>

            </Form>
            <Modal show={this.state.deleteModel} >
                <div className="modal-header">Delete Payment.
                </div>
                <div className="modal-body">
                    <div className="modal-title h6">{'Do you want to delete the payment ?'}</div>
                    <div className="text-right">
                        <button type="button" className="btn btn-success btn-sm mr-10" onClick={(e) => {
                            this.props.deletePayments(this.state.paymentId, this.props.invoiceId);
                            this.setState({ deleteModel: false, paymentId: 0 })
                        }}>Yes</button>
                        <button type="button" className="btn btn-danger btn-sm" onClick={(e) => {
                            e.preventDefault();
                            this.setState({ deleteModel: false, paymentId: 0 })

                        }}>No</button>
                    </div>
                </div>
            </Modal>
            <Modal show={this.state.editPaymentModel} >
                <div className="modal-header">{(this.state.paymentId||0)==0?"Add Payment":"Update Payment"}
                </div>
                <div className="modal-body">
                    <Form ref={c => {
                        paymentForm = c;
                    }}>
                        {/* {!isManagement()?<div className="row">
                           <div className="col-6">
                                <label htmlFor="accountName" className="form-label col-form-label">PAYMENT LOCATION</label>
                            </div>
                            <div className="col-6">
                            <div className="input-groups">
                                <Select className="form-control mb-10" validations={isManagement()?[]:[required]} requiredmsg="Please Select Payment Type." name="paymentLocation" onChange={(event) => { this.handlePayment(event) }} disabled={isDisabled} value={this.state.paymentLocation} >
                                    <option value="">Select Location</option>
                                    {
                                        locationList.length > 0 && locationList.filter(loc => loc.paymentLocation === 1).map((item, i) => {
                                            return (
                                                <option key={i} value={item.location}>{item.location}</option>
                                            )
                                        })
                                    }
                                </Select>
                            </div>
                            </div>
                        </div>:null} */}
                        <div className="row">
                            <div className="col-6">
                                <label htmlFor="accountName" className="form-label col-form-label">PAYMENT TYPE</label>
                            </div>
                            <div className="col-6">
                                <div className="input-groups">
                                    <Select className="form-control mb-10" validations={[required]} requiredmsg="Please Select Payment Type." onChange={(event) => { this.handlePayment(event) }} disabled={isDisabled} name="paymentType" value={this.state.paymentType} >
                                        <option value="">Select Payment Type</option>
                                        <option value="Cash">Cash</option>
                                        <option value="Credit">Credit</option>
                                        <option value="ManualCredit">Manual Credit</option>
                                        <option value="Check">Check</option>
                                        <option value="EmployeeAdvance">Employee Advance</option>
                                        <option value="Transfer">Transfer</option>
                                    </Select> {
                                        this.state.paymentType === 'Check' ?
                                            <input placeholder="Check #" type="text" name="chequeNo" onChange={(event) => { this.handlePayment(event) }} disabled={isDisabled} className="form-control mb-10" value={this.state.chequeNo} /> : null
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <label htmlFor="accountName" className="form-label col-form-label">AMOUNT</label>
                            </div>
                            <div className="col-6">
                                <div className="input-groups">
                                    <div class="input-groups-icon">$</div>
                                    <div class="input-groups-area">
                                        <Input type="number" validations={[required, NotZero]} requiredmsg="Please Enter Payment Amount." disabled={isDisabled} onChange={(event) => { this.handlePayment(event) }} className="form-control form-control-sm"
                                            name="paymentAmt" value={this.state.paymentAmt} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <label>&nbsp;</label>
                                <div className="text-right">
                                    <input type="button" disabled={isDisabled} className="btn btn-sm btn-success" value={(this.state.paymentId||0)==0?"Add Payment":"Update Payment"} onClick={() => {
                                        this.addPayment();
                                    }} />&nbsp;
                                    <input type="button" className="btn btn-sm btn-danger" disabled={isDisabled} onClick={() => {
                                        this.setPaymentToEmpty()
                                        this.setState({ editPaymentModel: false });
                                    }} value="Close" />
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>

            </Modal>
            <Modal show={this.state.addPaymentApprovalModal}
            contentLabel="Confirm Payment Approval"
            className="custom-small-centered-modal"
            overlayClassName="custom-overlay-centered-modal" >
             <div className="modal-header">Confirm Payment Approval  </div>
              <div className="modal-body">
                <div className="modal-title h6">{'Are you sure you want to approve the payment?'}</div>
                <div className="custom-form-group">
                <label>Payment Location</label>
                 <select
                  onChange={(event) =>{this.handlePaymentLocationChange(event)}}
                  className="form-control mb-10"
                 >
                 <option value="">Select Location</option>
                 {this.props.locationList.map((item, index) => (
                        <option key={index} value={item}>
                            {item.location}
                        </option>
                    ))} 
            </select> 
          </div>
          </div>
          
          <div className="row">
                            <div className="col-6">
                                <div className="text-right" style={{marginBottom:'10px',marginRight:'-15px'}}>
                                    <input type="button" disabled={isDisabled} className="btn btn-sm btn-success" value="Approve Payment" onClick={() => {
                                        this.addPayment();
                                    }} />&nbsp;
                                    <input type="button" className="btn btn-sm btn-danger" disabled={isDisabled} onClick={() => {
                                        this.setPaymentToEmpty()
                                        this.setState({ addPaymentApprovalModal: false });
                                    }} value="Close" />
                                </div>
                            </div>
                        </div>
        </Modal>
        </Fragment>);
    }
}
export default Payment