import React, { Component, Fragment } from 'react';
import TmDateTimePicker from '../../utilComponents/tmDateTimePicker/tmDateTimePicker';
import { GlobalValues } from '../../../util/constants.js';
import moment from 'moment';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import Select from 'react-validation/build/select';
import ReactSelect from '../../../components/utilComponents/tmSlimSelect/reactSelect';
import { isManagement } from '../../../util/customMethods';
import {
    required
} from "../../../util/validationMethods.js";
import {unverifiedCols} from '../../../util/tableColumnUtil/managedInvoicesCols'
import { ExcelFile, ExcelSheet, ExcelColumn } from 'react-data-export';

class UnverifiedInvoicesCtrl extends Component {
    render() {
		return (
            <Fragment>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card form-group">
                                <div className="card-body pd-10">
                                <Form ref={c => {
                                    this.formUnverifiedInvoicesInfo = c;
                                    this.props.initCallBack(c);
                                }}>
                                    <div className="form-row">
                                    {!isManagement()?<label htmlFor="asOf" className="form-label col-form-label">LOCATION</label>:null}
                                    {isManagement()?<label htmlFor="asOf" className="form-label col-form-label">Company</label>:null}
                                  
                                    {isManagement()?
                                    <div className="col-md-3 form-group">
                                    <ReactSelect name="idCompany"
                                                    closeMenuOnSelect={true}
                                                    notAmultiSelect={true}
                                                    isClearable={true}
                                                    options={this.props.childCompList.map((item, t) => {
                                                        return ({ value: item.optionVal, label: item.optionText })
                                                    })}
                                                    value={{
                                                        value: ((this.props.stateObj.idCompany || '').toString()),
                                                        label: (this.props.childCompList.find((e) => e.optionVal == (this.props.stateObj.idCompany || '').toString()) || {}).optionText
                                                    }}
                                                    onChange={(selectedOption) => {
                                                        this.props.onUnverifiedInvoicesFieldChange('idCompany', parseInt(selectedOption&&selectedOption.value||0));
                                                    }}
                                                />
                                    </div>:<div className="col-md-2 form-group">
                                            <select className="form-control" onChange={(e)=>{this.props.onUnverifiedInvoicesFieldChange('selectedLocation',e.target.value)}} >
                                                <option value=''>All</option>
                                                {(this.props.location||[]).map((loc, key) => {
                                                        return (
                                                            <option key={key} value={loc.location}>
                                                                {loc.location}
                                                            </option>
                                                        );
                                                    })}
                                            </select>
                                    </div>}
                                        <div className="ml-2 mr-2 form-group">
                                            <label htmlFor="asOf" className="form-label col-form-label">As Of</label>
                                        </div>
                                        <div className="col-2 form-group">
                                        <div>
                                        <TmDateTimePicker
                                        className="form-control"
                                        selectedDate={
                                            this.props.asofdate === "" || this.props.asofdate === null
                                                ? null
                                                : moment(
                                                    this.props.asofdate,
                                                    GlobalValues.dateFormat
                                                )._d
                                        }
                                        dateFormat={GlobalValues.dateFormat}
                                        validationArray={[required]}
                                        showYearDropdown={true}
                                        showmonthdropdown={true}
                                        name="asOfDate"
                                        reqmsg="As of date is required"
                                        onDateSelectChange={e => {
                                            this.props.onUnverifiedInvoicesFieldChange('asOfDate', e === null ? '' : moment(e).format(GlobalValues.dateFormat))
                                            this.forceUpdate()
                                        }} 
                                    />
                                    </div>
                                        </div>
                                        <div className="col-5 form-group">
                                        <button type="button" className="btn btn-success custom-reports-btn mr-2" onClick={this.props.Go} >Go</button>
                                            <button type="button" className="btn btn-warning custom-reports-btn mr-2" onClick={this.props.Export} >Export</button>
                                            {this.props.stateObj.render ? 
                                    <ExcelFile hideElement={true}
                                        filename={`${"NegativeBalanceReport"}${moment().format('MMDDYYhhmm')}`}
                                        fileExtension={'xlsx'}
                                    >
                                        <ExcelSheet 
                                            data={this.props.data || []}
                                            name={'Driver performance Report'}
                                        >
                                            {(unverifiedCols||[]).map(column => {
                                                return (
                                                    <ExcelColumn
                                                        key={column.accessor}
                                                        label={column.Header}
                                                        value={column.accessor}
                                                    />
                                                    );
                                                })}
                                        </ExcelSheet>
                                    </ExcelFile>
                                : null}
                                            <button type="button" className="btn btn-primary custom-reports-btn mr-2" onClick={this.props.email}>Email</button>
                                            <button type="button" className="btn btn-success custom-reports-btn mr-2" onClick={this.props.Print}>Print</button>
                                        </div>
                                    </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>       
            </Fragment>
        );
    }
}
export default UnverifiedInvoicesCtrl;