import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ExcelFile, ExcelSheet, ExcelColumn } from 'react-data-export';

import moment from 'moment';
import printJS from 'print-js';
import ARDetailsCtrl from '../../../components/reportsComponents/accounting/arDetailsCtrl';
import {isValidForm} from '../../../util/validationMethods';
import {utilMethods}  from '../../../util/util';
import { getCompanyId, isManagement } from '../../../util/customMethods';
import ReactTableDisplay from '../../../components/reportsComponents/commonComponents/ArReportTableConfig';
import { ARDetailsClmn } from '../../../components/reportsComponents/commonComponents/ARReportComClmn';
import { tableColumns } from '../../../components/reportsComponents/accounting/arDetails/table-column.config';
import {getARDetailsList, getARDetailsClearList} from '../../../actions/reportAction/accounting/arDetailsAction';
import {fetchReportAccountList, fetchReportAccountListClear} from '../../../actions/reportAction/accounting/reportAccountListAction';
const pageTitle = 'A/R Details'
let printJSHeaders = tableColumns.map(column => { 
    return {
        field: column.accessor, displayName: column.Header ? column.Header : column.accessor
    }
});
printJSHeaders = [ ...printJSHeaders, 
    { field: 'age', displayName: 'Age' },
    { field: 'dueDate', displayName: 'Due Date' },
    { field: 'displayInvoiceId', displayName: 'Invoice #' },
    { field: 'serviceCallDate', displayName: 'Service Call Date' },
    { field: 'balance', displayName: 'Balance' },
];
ARDetailsClmn.forEach(column => printJSHeaders.push({
    field: column.accessor,
    displayName: column.Header || column.accessor
}));
let arDetailsForm={
	formARDetailsInfo:{}
}

class ARDetails extends Component {
	constructor() {
		super();
		this.state = {
            accountType:'',
            accountId:'',
            asOfDate:'',
            idCompany:'',
            exportDataSet:[],
            render: false,
            dataFetched:false
		}
	}
	componentWillMount = () => {
		document.title = pageTitle;
        this.props.dispatch(getARDetailsClearList());
        if(!isManagement()){
        this.getAccountList(getCompanyId());
        }
    }
    getAccountList=(_idCompany)=>{
        this.props.dispatch(fetchReportAccountList(_idCompany));
    }
    onARDetailsFieldChange=(key, value)=>{
        this.setState({[key]:value});
        if(key === 'idCompany'){
            if(value !== ''){
                this.getAccountList(value);
            }else{
                this.props.dispatch(fetchReportAccountListClear());
            }  
        }
    }
    getByCompanyId=()=>{
        let id_company=0;
        if(!isManagement()){
            id_company=getCompanyId();
        }else{
            id_company=this.state.idCompany;
        }
        return id_company;
    }
    getARDetails=(e)=>{
        e.preventDefault();
        arDetailsForm.formARDetailsInfo.validateAll();
        if(isValidForm(arDetailsForm.formARDetailsInfo)){
            this.props.dispatch(getARDetailsList(this.state.accountType, this.state.accountId, this.state.asOfDate, this.getByCompanyId()))
            .then(() => {this.setState({ dataFetched: true })})   
            .catch(err => {
                console.error('fetchReport methods failed with error: ', err);
            });
        }
    }
    setReferenceToReactTable = ref => {
        this.reactTable = ref;
    };

    tableDataChanged = () => {
        console.log('table data has changed!');
        this.setState({
            data: this.reactTable.getResolvedState().sortedData
        });
    };
    exportToExcel = e => {
        e.preventDefault();
        arDetailsForm.formARDetailsInfo.validateAll();
        if(isValidForm(arDetailsForm.formARDetailsInfo)){
            const { accountType, accountId, asOfDate  } = this.state;
            this.props.dispatch(getARDetailsList(accountType, accountId, asOfDate, this.getByCompanyId()))
            .then(()=>{
                let _exportDataSet=[];                
                let setColHeader1=['ACCOUNT','AS OF','CURRENT','1-30 DAYS','31-60 DAYS','61-90 DAYS','90+ DAYS','Total'];
                let setColHeader2=['#INVOICE','SERVICE CALL DATE','DUE DATE','BALANCE'];
                let setCols1= ['accountName','asOfDate','currentDue','thirtyDaysDue','sixtyDaysDue','nintyDaysDue','moreThanNintyDaysDue','total'];
                let setCols2=['displayInvoiceId','serviceCallDate','dueDate','balance'];
                (this.props.arReportResponse||[]).map((rec)=>{
                    let setData=[];
                    let subSetData=[];
                     setCols1.map((col,i)=>{
                         if(i>1){
                            setData.push(utilMethods.convertToFloat(rec[col]));
                         }else{
                            setData.push(rec[col]);
                         }
                    });
                    subSetData=(rec.accountReceivableDetailInfo||[]).map((sRec)=>{
                        return setCols2.map(sCol=>{
                            if(sCol==='unPaid'){
                                return utilMethods.convertToFloat(sRec[sCol]||'',0);
                            }else if(sCol==='balance'){
                                return utilMethods.convertToFloat(sRec[sCol]||'')
                            }else{
                                return sRec[sCol]||'';
                            }                            
                        });

                    });
                    _exportDataSet.push({columns:setColHeader1,data:[setData]});
                    _exportDataSet.push({columns:setColHeader2,data:subSetData})
                });
                this.setState({exportDataSet:_exportDataSet})
            })
                .then(() => {this.setState({ dataFetched: true })})   
                .then(() => this.setState({ render: !this.state.render }))
                .catch(err => {
                    console.error('fetchReport methods failed with error: ', err);
                });
        }
    }
    printReport = e => {
        e.preventDefault();
        arDetailsForm.formARDetailsInfo.validateAll();
        if(isValidForm(arDetailsForm.formARDetailsInfo)){
        const { accountType, accountId, asOfDate  } = this.state;
        this.props.dispatch(getARDetailsList(accountType, accountId, asOfDate, this.getByCompanyId()))
                .then(() => this.setState({ dataFetched: true }))
                .then(() => {
                    let dataForPrinting = [];
                    this.props.arReportResponse.forEach(response => response.accountReceivableDetailInfo.forEach((detail, index) => {
                        dataForPrinting.push({
                            accountName: index === 0 ? response.accountName : '',
                            accountPrimaryContact: index === 0 ? response.accountPrimaryContact : '',
                            asOfDate: index === 0 ? response.asOfDate : '',
                            currentDue: index === 0 ? response.currentDue : '',
                            moreThanNintyDaysDue: index === 0 ? response.moreThanNintyDaysDue : '',
                            nintyDaysDue: index === 0 ? response.nintyDaysDue : '',
                            sixtyDaysDue: index === 0 ? response.sixtyDaysDue : '',
                            thirtyDaysDue: index === 0 ? response.thirtyDaysDue : '',
                            total: index === 0 ? response.total : '',
                            age: detail.age,
                            dueDate: detail.dueDate,
                            displayInvoiceId: detail.displayInvoiceId,
                            serviceCallDate: detail.serviceCallDate,
                            balance:  detail.balance
                        });
                    }));
                    console.log("DATA", dataForPrinting)
                    printJS({printable: dataForPrinting, properties: printJSHeaders, type: 'json', documentTitle: 'AR Details'});
                })    
                .catch(err => {
                    console.error('fetchReport methods failed with error: ', err);
                });
            }
    }
	render() {
		return (
            <Fragment>
                <div className="page-header">
                    <div className="row mb-10">
                        <div className="col-md-12 col-sm-12">
                            <div className="title">
                                <h4> Account Receivable Details</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pd-10 bg-white border-radius-4 box-shadow">
                    <ARDetailsCtrl
                    idCompany={this.state.idCompany}
                    accountTypes={this.props.accountTypes}
                    childCompList={this.props.childCompList}
                    initCallBack={(frm)=>{
						arDetailsForm.formARDetailsInfo=frm;
                    }} asofdate={this.state.asOfDate}
                    onARDetailsFieldChange={(key, value)=>this.onARDetailsFieldChange(key, value)}
                    accList={this.props.accountList}
                    accountId={this.state.accountId}
                    accountType={this.state.accountType}
                    getARDetails={(e)=>this.getARDetails(e)}
                    exportARDetailsToExcel={(e)=>this.exportToExcel(e)}
                    printARDetailsReport={(e)=>this.printReport(e)}/>
                    {this.state.render ? 
                        <ExcelFile
                                hideElement={true}
                                filename={`${"ARDetail_"}${moment().format('MMDDYYhhmm')}`}
                                fileExtension={'xlsx'}
                        >
                        <ExcelSheet dataSet={this.state.exportDataSet}  name={'AR Details'}>
                        </ExcelSheet>
                        </ExcelFile>
                    : null}
                    {this.state.dataFetched &&
                        <ReactTableDisplay
                            data={this.props.arReportResponse}
                            tableDataChanged={this.tableDataChanged}
                            tableReference={this.setReferenceToReactTable}
                            columns={tableColumns}
                            onExpandedChange={this.props.onExpandedChange}
                        />
                    }
                </div>
            </Fragment>
		);
	}
}
const mapStateToProps = (state, ownProps) => {
	return {
        accountTypes:state.accountTypes.accountTypes,
        accountList:state.reportsAccountList.reportAccountList,
        arReportResponse: state.arDetailsList.arDetails,
        childCompList:state.searchInvoiceList.childComList
	}
};

export default withRouter(connect(mapStateToProps)(ARDetails));
