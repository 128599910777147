import * as dropDownsAction from '../actions/adminAction/dropDownDataAction'
import * as initialReqInfoAction from '../actions/dispatchAction/initialReqInfoAction'
import * as reviewInboxAction from '../actions/dispatchAction/reviewInboxAction';
import {LAUNCH_STORE_NAME,IS_LOCAL_STORAGE,callStatus,priorities,mapfilterData} from '../util/constants';
import { store } from '../store/configureStore';
import  moment from 'moment'
export const LAUNCH_URL =window.location.origin;
export const ROOT_DISPATCHER = 'dispatch';
export const ROOT_ADMIN = 'admin';
export const ROOT_REPORTS = 'reports';
export const convertToFloat=(value,decimalLength=2)=>{
  return parseFloat((isNaN(parseFloat(value))?0.00:parseFloat(value)).toFixed(decimalLength));
  
}
export const getCompanyId=()=>{
  let _authInfo= getDataFromLaunchStore('authInfo');
 if(Object.keys(_authInfo).length>0){
   return _authInfo.userDetails.companyId
 }
 else{
   return 0
 }
}
export const isManagement=()=>{
  let _authInfo= getDataFromLaunchStore('authInfo');
 if(Object.keys(_authInfo).length>0){
   return _authInfo.userDetails.isManagement
 }
 else{
   return false
 }
}

export const isManagemed=()=>{
  let _authInfo= getDataFromLaunchStore('authInfo');
 if(Object.keys(_authInfo).length>0){
   return ((_authInfo.userDetails.companyType||"").toUpperCase())=="MANAGED";
 }
 else{
   return false
 }
}

export const compStr = (str1, str2) => {
  let strOne = (str1 || '').toUpperCase();
  let strTwo = (str2 || '').toUpperCase();
  return strOne == strTwo;
}

//get subdomain
export const getSubDomain=()=>{
  let host = window.location.host;
  host=host.replace("www.",'');
  host=host.replace('.com','');
  if (host.split('.').length>0) {
    return (host.split('.'))[0]; 
    // return 'devmanage'; 
  }else{
      return '';
  }    
}

 //function to validate 
 export const validate=(value,type)=>{
  switch(type.toUpperCase()) {
    case 'STRING':
      return (value == null || value === ''|| value===undefined);
}
}
export const getUserName=()=>{
  let _authInfo= getDataFromLaunchStore('authInfo');
 if(Object.keys(_authInfo).length>0){
   return _authInfo.userDetails.firstName+' '+_authInfo.userDetails.lastName;
 }
 else{
   return undefined;
 }
}
export const getLoginUserName=()=>{
  let _authInfo= getDataFromLaunchStore('authInfo');
 if(Object.keys(_authInfo).length>0){
   return _authInfo.userDetails.userId;
 }
 else{
   return undefined;
 }
}
export const getLoginUserId=()=>{
  let _authInfo= getDataFromLaunchStore('authInfo');
 if(Object.keys(_authInfo).length>0){
   return _authInfo.userDetails.employeeId;
 }
 else{
   return undefined;
 }
}
export const getAccessLevels=()=>{
  let accessLevels= (getDataFromLaunchStore('accessLevel'))||{};
  return (accessLevels||[])
}
export const dashboardFilterByType = (type) => {
  let filter = localStorage.getItem(type) || {};
  if (Object.keys(filter).length > 0)
  {
    return JSON.parse(filter);
  }
  else if (type === 'byStatus')
   {return callStatus;}
  else if (type === 'byPriority')
   {return priorities;}
  else if (type === 'mapFilter')
    {return mapfilterData;}
}
export const setDashboardFilterByType=(type,filter)=>{
  localStorage.setItem(type,JSON.stringify(filter));
}
export const getDashboardAccessLevels=()=>{
  let dashboardAccessLevels= (getDataFromLaunchStore('dashboardAccess'))||{};
  return (dashboardAccessLevels||[])
}
export const editPermission = (itemComp) => {
  if (isManagement() || ((itemComp||0)==0))
    return true;
  else
    return (itemComp == getCompanyId());
}
export const getAccessLevelsByModule=(subModuleName=undefined)=>{
  const mainModuleName='Admin'
  let accessGroup=getAccessLevels()||{};
    if(Object.keys(accessGroup).length>0){
        let searchKey=Object.keys(accessGroup[mainModuleName]).find((mod)=>{           
            return (mod.toLowerCase()===subModuleName.toLowerCase())
        })
        if(searchKey!==undefined){
          let searchObj=accessGroup[mainModuleName][searchKey][0]
          searchObj['basicAccess']=(searchObj['basicAccess']==undefined?{}:searchObj['basicAccess'])
          return searchObj;
        }else{
          let obj={'basicAccess':{}}
          Object.keys(accessGroup[mainModuleName]).some((mod)=>{  
            if(accessGroup[mainModuleName][mod].length>0) {
              obj= accessGroup[mainModuleName][mod].find((item)=>{
                return item.pageName.toLowerCase()===subModuleName.toLowerCase()
              });
              if(obj){
                obj['basicAccess']=(obj['basicAccess']==undefined?{}:obj['basicAccess'])
                return true;
              }else{
                obj={'basicAccess':{}}
                return false;
              }
            }else{
              return false;
            } 
        })
          return obj;
        }
    }
}

export const getAccessLevelsByModuleName=(subModuleName=undefined)=>{
  const mainModuleName='Dispatcher'
  let accessGroup=getAccessLevels()||{};
    if(Object.keys(accessGroup).length>0){
        let searchKey=Object.keys(accessGroup[mainModuleName]||"").find((mod)=>{           
            return (mod.toLowerCase()===subModuleName.toLowerCase())
        })
        if(searchKey!==undefined){
          let searchObj=accessGroup[mainModuleName][searchKey]
          searchObj['basicAccess']=(searchObj['basicAccess']==undefined?{}:searchObj['basicAccess'])
          return searchObj;
        }else{
          let obj={'basicAccess':{}}
          Object.keys(accessGroup[mainModuleName]||"").some((mod)=>{  
            if(accessGroup[mainModuleName][mod].length>1) {
              obj= accessGroup[mainModuleName][mod].find((item)=>{
                return item.pageName.toLowerCase()===subModuleName.toLowerCase()
              });
              if(obj){
                obj['basicAccess']=(obj['basicAccess']==undefined?{}:obj['basicAccess'])
                return true;
              }else{
                obj={'basicAccess':{}}
                return false;
              }
            }else{
              return false;
            } 
        })
          return obj;
        }
    }
}
//Read Launch Session Data
export const setDataToLaunchStore=(key,ObjData)=>{
  let _store=usingLaunchStore();
  _store[key]=JSON.stringify(ObjData);
  if(IS_LOCAL_STORAGE){
    localStorage.setItem(LAUNCH_STORE_NAME,JSON.stringify(_store))
  }else{
    sessionStorage.setItem(LAUNCH_STORE_NAME,JSON.stringify(_store))
  }
}
export const getDataFromLaunchStore=(key)=>{
  let _store=usingLaunchStore();
  return _store[key]||{}
}
const usingLaunchStore=()=>{
  let store={}  
  try{
    let existingStore='';
    if(IS_LOCAL_STORAGE){
      existingStore=localStorage.getItem(LAUNCH_STORE_NAME);
    }else{
      existingStore=sessionStorage.getItem(LAUNCH_STORE_NAME);
    }
    if(existingStore){
      existingStore=(existingStore===''||existingStore==='null')?JSON.parse({}):JSON.parse(existingStore)
      Object.keys(existingStore).forEach(keyName=>{
        let _jsonParse
        try{
          _jsonParse=JSON.parse(existingStore[keyName]);
          store[keyName]=_jsonParse
        }catch(ex){
          store[keyName]=existingStore[keyName];
        }
      })
    }else{
      if(IS_LOCAL_STORAGE){
        localStorage.setItem(LAUNCH_STORE_NAME,JSON.stringify(store))
      }else{
        sessionStorage.setItem(LAUNCH_STORE_NAME,JSON.stringify(store))
      }
    }
  }catch(e){
    if(IS_LOCAL_STORAGE){
      localStorage.setItem(LAUNCH_STORE_NAME,JSON.stringify(store))
    }else{
      sessionStorage.setItem(LAUNCH_STORE_NAME,JSON.stringify(store))
    }
  }
  return store;
}
export const getRefreshToken=()=>{
  let _authInfo=getDataFromLaunchStore('authInfo');
  if(Object.keys(_authInfo).length>0){
   return _authInfo.oaAuthTokens.refresh_token||undefined
  }else{
    return undefined
  }
}
export const setAuthTokens=(resp)=>{
  let _authInfo=getDataFromLaunchStore('authInfo');
  let _respObj=_authInfo.oaAuthTokens ||{};
  _respObj.access_token=resp.access_token;
  _respObj.refresh_token=resp.refresh_token;
  _respObj.token_creationTime=resp.expires_in;
  _authInfo.oaAuthTokens=_respObj
  setDataToLaunchStore('authInfo',_authInfo)
}
export const clearSessionData=()=>{
      setDataToLaunchStore('authInfo',{})
}

export const sortName=(key)=>{
let sortName="";
let stateList = (store.getState()).states.stateList || [];
if(stateList.length>0 && (key||"") !=""){
  let obj=stateList.find((item)=>(item.optionVal||'').toUpperCase()===key.toUpperCase())
  if(obj){
    sortName=obj.sortName;
  }
  return sortName;
}

}
export const longName=(key)=>{
  let longName="";
  let stateList = (store.getState()).states.stateList || [];
  if(stateList.length>0 && (key||"") !=""){
    let obj=stateList.find((item)=>(item.sortName||'').toUpperCase()===key.toUpperCase());
    if(obj){
      longName=obj.optionVal;
    }
  }
    return longName;
}

export const float=(val)=>{
  let valTofmt=(val===''?0:val);
  return parseFloat(valTofmt||0.00).toFixed(2);
}
export const returnDateObj=(key)=>{
  if(key == null)
      return null;
  let keyObj=moment(key); 
      return keyObj; 
 }
 export const fillDropDownsData=()=>{
  return async (dispatch)=>{
    await dispatch(dropDownsAction.getStateList())
    await dispatch(initialReqInfoAction.fetchAccountList())
    await dispatch(dropDownsAction.getAllTowTypes())
    await dispatch(dropDownsAction.getDepartments())
    await dispatch(dropDownsAction.getLocationList())
    await dispatch(dropDownsAction.getAccountType())
    await dispatch(dropDownsAction.getSalesRep())
    await dispatch(dropDownsAction.getCallReasons())
    await dispatch(dropDownsAction.getRatePlanList())
    //await dispatch(dropDownsAction.getUserDefaultCharges())
    await dispatch(dropDownsAction.getCommissionTypes())
    await dispatch(dropDownsAction.getLocationDetailsBySearch())
    await dispatch(reviewInboxAction.getReviewLocationList(null, false))
    if(!isManagement()){
      await dispatch(dropDownsAction.getLicenseTypes())
    }  
    await dispatch(dropDownsAction.getChildList())
  }
} 
export const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});
export function showFile(blob,fileName){
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  var newBlob = new Blob([blob], {type: "application/pdf"})
  
  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob,fileName+'.pdf');
    return;
  } 

  // For other browsers: 
  // Create a link pointing to the ObjectURL containing the blob.
  const blobURL = window.URL.createObjectURL(blob);
  const tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.href = blobURL;
  tempLink.setAttribute('download', fileName+'.pdf');
  // Safari thinks _blank anchor are pop ups. We only want to set _blank
  // target if the browser does not support the HTML5 download attribute.
  // This allows you to download files in desktop safari if pop up blocking
  // is enabled.
  // if (typeof tempLink.download === 'undefined') {
  //   tempLink.setAttribute('target', '_blank');
  // }
  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(blobURL);
  }, 100);

  


//     var fileLink = document.createElement('a');
//     fileLink.href = window.URL.createObjectURL(newBlob);
// fileLink.download = 'title';
// fileLink.click();
// window.open(fileLink.href,'_blank');
  // anchor.href = window.URL.createObjectURL(newBlob);
 
  // anchor.name=fileName+'.pdf';
  // anchor.dataset.downloadurl = ['application/pdf', anchor.download, anchor.href].join(':');

  // if(typeof InstallTrigger !== 'undefined'){
  //     window.open(anchor.href,'_blank');
  //     return;
  // }    
  // anchor.target='_blank';
  // anchor.click();

  //var link = document.createElement('a');
 // link.href = data;
 // link.target='_blank';
 // link.setAttribute('open', fileName+".pdf");
  // link.click();
  setTimeout(function(){
    // For Firefox it is necessary to delay revoking the ObjectURL
  //  window.URL.revokeObjectURL(anchor.href);
  }
  , 100);
}
export const reportText = (type) => {
  let name = '';
  switch ((type || '').toLowerCase()) {
      case 'rateplan':
          name = 'Summary details by rate plan'
          break;
      case 'towtype':
          name = 'Summary details by towType'
          break;
      case 'truck':
          name = 'Summary details by truck'
          break;
      case 'reason':
          name = 'Summary details by reason'
          break;
      case 'driver':
          name = 'Summary details by driver'
          break;
  }
  return name;
}

/**
 * Calculates labor charge based on billing parameters and total hours
 * @param {Object} billing - Contains serviceHourlyRate, serviceMinHours, serviceIncrementHours, serviceFreeHours
 * @param {number} totalHrs - Total hours worked
 * @returns {number} Calculated labor charge
 */
export const calculateLaborCharge = (billing, totalHrs) => {
  try {
      // Early return for invalid inputs
      if (!billing || isNaN(totalHrs) || totalHrs < 0) return 0;

      // Normalize inputs
      const hourlyRate = Number(billing.service_hourly_rate) || 0;
      const minHours = Number(billing.service_min_hours) || 0;
      const incrHours = Number(billing.serviceIncrementHours) || 0;
      const freeHours = Number(billing.service_increment_hours) || 0;

      let billableHours = 0;

      // Calculate billable hours
      if (minHours >= totalHrs) {
          billableHours = minHours;
      } else if (incrHours > 0) {
          const remainingTime = totalHrs - minHours;
          billableHours = minHours + (incrHours * Math.ceil(remainingTime / incrHours));
      } else {
          billableHours = totalHrs;
      }

      // Apply free hours reduction
      billableHours = freeHours >= billableHours ? 0 : 
                     freeHours > 0 ? billableHours - freeHours : 
                     billableHours;

      return Number((billableHours * hourlyRate).toFixed(2));
  } catch (error) {
      console.error('Error calculating labor charge:', error);
      return 0;
  }
};