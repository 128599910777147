import React, { Component, Fragment } from 'react';
import TmDateTimePicker from '../../utilComponents/tmDateTimePicker/tmDateTimePicker';
import ReactSelect from '../../../components/utilComponents/tmSlimSelect/reactSelect';
import { GlobalValues } from '../../../util/constants.js';
import moment from 'moment';
import Form from 'react-validation/build/form';
import {
    required
} from "../../../util/validationMethods.js";
import { ExcelFile, ExcelSheet, ExcelColumn } from 'react-data-export';
import { isManagement } from '../../../util/customMethods';
class ReceiptsCtrl extends Component {
    render() {
        const {stateObj} = this.props;
		return (
            <Fragment>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card form-group">
                                <div className="card-body pd-10">
                                <Form ref={c => {
                                        this.formReceiptsInfo = c;
                                        this.props.initCallBack(c);
                                    }}>
                                    <div className="form-row">
                                    <div className="col-4 form-group">
                                            <div className="form-group row">
                                                <div className="col">
                                                    <div className="custom-control custom-control custom-radio">
                                                        <input type="radio" className="custom-control-input" name="rdreceipt" id="inlineRequestBy" 
                                                       onChange={()=>{ this.props.onReceiptsFieldChange('isRequestedBy',!stateObj.isRequestedBy)}} checked={stateObj.isRequestedBy}/>
                                                        <label htmlFor="inlineRequestBy" className="custom-control-label">REQUEST BY</label>
                                                    </div>
                                                </div>

                                                <div className="col">
                                                    <div className="custom-control custom-control custom-radio">
                                                        <input type="radio" className="custom-control-input" name="rdreceipt" id="inlineBillTo"
                                                        onChange={()=>{ this.props.onReceiptsFieldChange('isRequestedBy',!stateObj.isRequestedBy)}} checked={!stateObj.isRequestedBy}/>
                                                        <label htmlFor="inlineBillTo" className="custom-control-label">BILL TO</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-1 form-group">
                                            <label htmlFor="from" className="form-label col-form-label">ACCOUNT</label>
                                        </div>
                                        <div className="col-2 form-group">
                                        <ReactSelect name="idCompany"
                                                    closeMenuOnSelect={true}
                                                    notAmultiSelect={true}
                                                    isClearable={true}
                                                    options={(this.props.accountList||[]).map((item, t) => {
                                                        return ({ value: item.optionVal, label: item.optionText })
                                                    })}
                                                    value={{
                                                        value: ((stateObj.accountId || '').toString()),
                                                        label: ((this.props.accountList||[]).find((e) => e.optionVal == (stateObj.accountId || '').toString()) || {}).optionText
                                                    }}
                                                    onChange={(selectedOption) => {
                                                        this.props.onReceiptsFieldChange('accountId', parseInt(selectedOption&&selectedOption.value||0));
                                                    }}
                                                />
                                        </div>
                                        {isManagement()? <div className="col-1 form-group">
                                            <label htmlFor="from" className="form-label col-form-label">COMPANY</label>
                                        </div>:null}
                                       {isManagement()?
                                       <div className="col-2 form-group">
                                        <ReactSelect name="idCompany"
                                                    closeMenuOnSelect={true}
                                                    notAmultiSelect={true}
                                                    isClearable={true}
                                                    options={(this.props.childCompList||[]).map((item, t) => {
                                                        return ({ value: item.optionVal, label: item.optionText })
                                                    })}
                                                    value={{
                                                        value: ((stateObj.idCompany || '').toString()),
                                                        label: ((this.props.childCompList||[]).find((e) => e.optionVal == (stateObj.idCompany || '').toString()) || {}).optionText
                                                    }}
                                                    onChange={(selectedOption) => {
                                                        this.props.onReceiptsFieldChange('idCompany', parseInt(selectedOption&&selectedOption.value||0));
                                                    }}
                                                />
                                        </div>:null}
                                        </div>
                                        <div className="form-row">
                                        <div className="offset-4"></div>
                                        <div className="col-1 form-group">
                                            <label htmlFor="from" className="form-label col-form-label">FROM</label>
                                        </div>
                                        <div className="col-2 form-group">
                                        <div>
                                        <TmDateTimePicker
                                        className="form-control"
                                        selectedDate={
                                            this.props.fromdate === "" || this.props.fromdate === null
                                                ? null
                                                : moment(
                                                    this.props.fromdate,
                                                    GlobalValues.dateFormat
                                                )._d
                                        }
                                        dateFormat={GlobalValues.dateFormat}
                                        validationArray={[required]}
                                        showYearDropdown={true}
                                        showmonthdropdown={true}
                                        name="fromDate"
                                        reqmsg="From date is required"
                                        onDateSelectChange={e => {
                                            this.props.onReceiptsFieldChange('fromDate', e === null ? '' : moment(e).format(GlobalValues.dateFormat))
                                            this.forceUpdate()
                                        }} 
                                    />
                                    </div>
                                        </div>
                                        <div className="col-1 form-group">
                                            <label htmlFor="to" className="form-label col-form-label">TO</label>
                                        </div>
                                        <div className="col-2 form-group">
                                        <div>
                                        <TmDateTimePicker
                                        className="form-control"
                                        selectedDate={
                                            this.props.todate === "" || this.props.todate === null
                                                ? null
                                                : moment(
                                                    this.props.todate,
                                                    GlobalValues.dateFormat
                                                )._d
                                        }
                                        dateFormat={GlobalValues.dateFormat}
                                        validationArray={[required]}
                                        showYearDropdown={true}
                                        showmonthdropdown={true}
                                        name="toDate"
                                        reqmsg="To date is required"
                                        onDateSelectChange={e => {
                                            this.props.onReceiptsFieldChange('toDate', e === null ? '' : moment(e).format(GlobalValues.dateFormat))
                                            this.forceUpdate()
                                        }} 
                                    />
                                    </div>
                                        </div>
                                        <div className="offset-5"></div>
                                        <div className="col-6 form-group">
                                            <button type="button" className="btn btn-success custom-reports-btn mr-3" 
                                            onClick={this.props.Go}>Go</button>
                                            <button type="button" className="btn btn-warning custom-reports-btn mr-2" onClick={this.props.Export}>Export</button>
                                            {stateObj.render ? 
                                <ExcelFile hideElement={true}
                                    filename={`${"Receipts_"}${moment().format('MMDDYYhhmm')}`}
                                    fileExtension={'xlsx'}
                                >
                                    <ExcelSheet 
                                        data={this.props.data || []}
                                        name={'Receipts Report'}
                                    >
                                        {this.props.cols.map(column => {
                                            return (
                                                <ExcelColumn
                                                    key={column.accessor}
                                                    label={column.Header}
                                                    value={column.accessor}
                                                />
                                                );
                                            })}
                                    </ExcelSheet>
                                </ExcelFile>
                            : null}
                                            <button type="button" className="btn btn-primary custom-reports-btn mr-2" onClick={this.props.Email}>Email</button>
                                            <button type="button" className="btn btn-success custom-reports-btn mr-2" onClick={this.props.Print}>Print</button>
                                        </div>
                                    </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>       
            </Fragment>
        );
    }
}
export default ReceiptsCtrl;