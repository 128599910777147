import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { notify } from '../../../util/notifications';
import ReceiptsCtrl from '../../../components/reportsComponents/accounting/receiptsCtrl';
import ReceiptsTable from '../../../components/reportsComponents/accounting/receiptsTable';
import {initReciptState} from '../../../util/stateUtil/initialStateForComponents';
import { isManagement, getCompanyId } from '../../../util/customMethods';
import { cleanReciptReport ,getReciptReport ,emailReciptReport} from '../../../actions/reportAction/accounting/reciptAction';
import { isValidForm } from '../../../util/validationMethods';
import { receiptCol1, receiptcols, } from '../../../util/tableColumnUtil/audit';
const pageTitle = 'Payment Audit Report';
const printJSHeaders = receiptcols.map(column => ({
    field: column.accessor,
    displayName: column.Header || column.accessor
}))
const convertToFloat=(value,decimalLength=2)=>{
    return parseFloat((isNaN(parseFloat(value))?0.00:parseFloat(value)).toFixed(decimalLength));
    
  }
let ReportForm = {}

class Receipts extends Component {
	state = {
        ...initReciptState
		}

        formatPrintDocument=(reciptRepoData)=>{  
            if(reciptRepoData.receiptDetails.length<=0){
                return false;
            } 
            let mainContent=`<h6 style="display:block; text-align:center;">Receipts Report</h6>
            <div style="width:100%; font-size:7pt; padding-bottom:20px;"><span style="float:left"><strong style="font-size:8px">From Date: </strong>${moment(reciptRepoData.fromDate).format('MM/DD/YYYY')}</span>
            <span style="float:right"><strong style="font-size:8px">To Date: </strong>${moment(reciptRepoData.toDate).format('MM/DD/YYYY')}</span>
            </div>`;    
            let summaryCols=[]
            let _receiptsTotals=[];
            (reciptRepoData.receiptsTotalsDetails||[]).map((item,k)=>{
                let obj={}
                obj['location']=item.location;
                item.receiptsTotals.map((type)=>{
                    obj[type.paymentMethod]=convertToFloat(type.amount,2)               
                });
                _receiptsTotals.push(obj);
                summaryCols=summaryCols.concat(Object.keys(obj));
            });
            summaryCols=Array.from(new Set(summaryCols));
            console.log(_receiptsTotals);
            console.log(summaryCols);
            let summaryContent='<table style="font-size:7pt;" border="1" cellspacing="0" width="100%">'
            let summaryHead='<tr><thead>'
            summaryCols.map(col=>{
                summaryHead+='<th style=" text-align:left; text-transform: capitalize;">'+(col||'').toLowerCase()+'</th>';
            });
            summaryHead+='<th>Total</th>';
            summaryHead+='</thead></tr>';
            summaryContent+=summaryHead;
            let summaryTotals=[];
    
            (_receiptsTotals||[]).map((item,i)=>{
                let total=0.00
                let rowContent= '<tr>';
                summaryCols.map((col,i)=>{
                    if(i>0){
                        total+= convertToFloat(item[col]) ;                    
                        rowContent+='<td style="text-align:right"> $'+convertToFloat(item[col]).toFixed(2)+'</td>';
                        summaryTotals[i]= summaryTotals[i]?(convertToFloat(summaryTotals[i])+convertToFloat(item[col])):convertToFloat(item[col]);
                    }
                    else{
                        rowContent+='<td style="text-align:left">'+(item[col]||'')+'</td>';
                        summaryTotals[i]=''
                    }    
                })
                rowContent+='<td style="text-align:right"> $'+convertToFloat(total).toFixed(2)+'</td>'
                rowContent+='</tr>';
                summaryContent+=rowContent;
            })
            
            let summaryTotalContent='<tr>'
            let grandTot=0.00;
            summaryTotals.map((item,i)=>{
                summaryTotalContent+='<td style="text-align:right">';
                if(i>0){                
                    summaryTotalContent+=convertToFloat(item).toFixed(2);
                    grandTot+=convertToFloat(item);                
                }else{
                    summaryTotalContent+='Total'
    
                }
                summaryTotalContent+='</td>';
                
            });
            summaryTotalContent+='<td style="text-align:right">$'+grandTot.toFixed(2)+'</td>'
            summaryTotalContent+='</tr>'
    
            summaryContent+=summaryTotalContent+'</table>';
    
            let printContent='<p style="display:block; text-align:left; font-size:13px;">Receipts Details</p><table style="font-size:7pt; page-break-inside:auto" class="print-act-receipts" border="1" cellspacing="0" width="100%">';
            let rowHead='<tr>'
            printJSHeaders.map(col=>{
                rowHead+='<th>'+(col.displayName||'')+'</th>' 
            });
            rowHead+='</tr>'
            printContent+=rowHead;
            (reciptRepoData.receiptDetails||[]).map((item,i)=>{
                let rowContent= '<tr style="page-break-inside:avoid; page-break-after:auto ">'
                printJSHeaders.map(col=>{
                    if((col.field||'').toUpperCase()=='amount'.toUpperCase()){
                        rowContent+='<td style="text-align:right;">$'+convertToFloat(item[col.field]||'').toFixed(2)+'</td>'
    
                    }else{
                        rowContent+='<td>'+(item[col.field]||'')+'</td>' 
                    }
                    
                })
                rowContent+='</tr>';
                printContent+=rowContent;
            }) 
            printContent+='</table>'
            var pri = document.getElementById("ifmcontentstoprint").contentWindow;       
            pri.document.open();
            pri.document.write(mainContent+summaryContent+printContent);
            pri.document.close();
            pri.focus();
            pri.print();
        }


        Go = async () => {
            const { dispatch } = this.props;
            let status = false;
            let payload = this.buildObject();
            if (payload) {
                if (isManagement() && (this.state.idCompany || 0) == 0) {
                    status = true;
                }
                const res = await dispatch(getReciptReport(payload, status));
                if (res.statusCode === 200) {
                    notify.createNotification('success', res.statusMsg, this.props.refNotify);
                    this.setState({ render: false });
                } else {
                    notify.createNotification('error', res.statusMsg, this.props.refNotify);
                };
            }
        }
    
        Export = async () => {
            const { dispatch } = this.props;
            let status = false;
            let payload = this.buildObject();
            if (payload) {
                if (isManagement() && (this.state.idCompany || 0) == 0) {
                    status = true;
                }
                const res = await dispatch(getReciptReport(payload, status));
                if (res.statusCode === 200) {
                    this.setState({ render: !this.state.render });
                    notify.createNotification('success', 'Report exported successfully.', this.props.refNotify);
                    this.setState({ render: false });
                } else {
                    this.setState({ render: false });
                    notify.createNotification('error', res.statusMsg, this.props.refNotify);
                };
            }
        }
    
        Print = async () => {
            const { dispatch } = this.props;
            let status = false;
            let payload = this.buildObject();
            if (payload) {
                if (isManagement() && (this.state.idCompany || 0) == 0) {
                    status = true;
                }
                const res = await dispatch(getReciptReport(payload, status));
                if (res.statusCode === 200) {
                   this.formatPrintDocument(this.props.receiptReport);
                } else {
                    notify.createNotification('error', res.statusMsg, this.props.refNotify);
                };
            }
        }
    
        Email = async () => {
            const { dispatch } = this.props;
            let status = false;
            let payload = this.buildObject();
            if (payload) {
                if (isManagement() && (this.state.idCompany || 0) == 0) {
                    status = true;
                }
                const res = await dispatch(emailReciptReport(payload, status));
                if (res.statusCode === 200) {
                    notify.createNotification('success', res.statusMsg, this.props.refNotify);
                } else {
                    notify.createNotification('error', res.statusMsg, this.props.refNotify);
                };
            }
        }
    
        /* Building all invoice object for post request */
        buildObject = () => {
            const stateObj = this.state;
            const constractObj = {
                idCompany: isManagement() ? (this.state.idCompany || getCompanyId()) : getCompanyId(),
                fromDate: stateObj.fromDate,
                toDate: stateObj.toDate,
                isRequestedBy: stateObj.isRequestedBy,
                accountId:stateObj.accountId
            };
            return constractObj;
        }
    
        
	componentWillMount = () => {
		document.title = pageTitle;
        this.props.dispatch(cleanReciptReport());
		
    }
    onReceiptsFieldChange=(key, value)=>{
        this.setState({[key]:value});
    }
	render() {
        let stateObj = this.state;

//////////////////////////////////
const summaryTableRows = this.props.receiptReport.receiptsTotalsDetails.map(location => {
    let row =  {
        "location": location.location,
        "cash": "",
        "check": "",
        "credit": "",
        "transfer": "",
        "advance": "",
        "total": location.totalByLoc||'',
    }
    
    location.receiptsTotals.forEach(payType => {
        console.log("PAYTYPE", payType)
        if(payType.paymentMethod === "CASH"){
            row["cash"] = payType.amount
        } else if(payType.paymentMethod === "CHECK"){
            row["check"] = payType.amount
        } else if(payType.paymentMethod === "CREDIT"){
            row["credit"] = payType.amount
        } else if(payType.paymentMethod === "TRANSFER"){
            row["transfer"] = payType.amount
        } else if(payType.paymentMethod === "EMPLOYEEADVANCE"){
            row["advance"] = payType.amount
        } 
        
    })
    return row
})

const summaryTotalsRow =  {
    "location": "TOTALS",
    "cash": "",
    "check": "",
    "credit": "",
    "transfer": "",
    "advance": "",
    "total": this.props.receiptReport.paymentTypeTotal||''
}

this.props.receiptReport.receiptPaymentTypeTotals.forEach(payType => {
    
    if(payType.paymentMethod === "Total CASH"){
        summaryTotalsRow["cash"] = payType.total
    } else if(payType.paymentMethod === "Total CHECK"){
        summaryTotalsRow["check"] = payType.total
    } else if(payType.paymentMethod === "Total CREDIT"){
        summaryTotalsRow["credit"] = payType.total
    } else if(payType.paymentMethod === "Total TRANSFER"){
        summaryTotalsRow["transfer"] = payType.total
    } else if(payType.paymentMethod === "Total EMPLOYEEADVANCE"){
        summaryTotalsRow["advance"] = payType.total
    } 
})
const sortedSummaryRows = summaryTableRows.map(data => {
    for(let key in data){
        data[key] = typeof data[key] === ('undefined' || 'object') || key.startsWith('_') || Number(data[key]).toString() === 'NaN' ? data[key] : Number(data[key])
    }
    return data
})

const sortedTotalsRows = [summaryTotalsRow].map(data => {
    for(let key in data){
        data[key] = typeof data[key] === ('undefined' || 'object') || key.startsWith('_') || Number(data[key]).toString() === 'NaN' ? data[key] : Number(data[key])
    }
    return data
})

////////////////////////////



		return (
            <Fragment>
                <div className="page-header">
                    <div className="row mb-10">
                        <div className="col-md-12 col-sm-12">
                            <div className="title">
                                <h4> Receipts</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pd-10 bg-white border-radius-4 box-shadow">
                    <ReceiptsCtrl 
                     Go={() => {
                        ReportForm.validateAll()
                        if (isValidForm(ReportForm)) {
                            this.Go();
                        }
                    }}
                    Export={() => {
                        ReportForm.validateAll()
                        if (isValidForm(ReportForm)) {
                            this.Export();
                        }
                    }}
                    Email={() => {
                        ReportForm.validateAll()
                        if (isValidForm(ReportForm)) {
                            this.Email();
                        }
                    }} Print={() => {
                        ReportForm.validateAll()
                        if (isValidForm(ReportForm)) {
                            this.Print();
                        }
                    }}
                    stateObj = {stateObj}   
                    accountList={this.props.accountList}
                    childCompList={this.props.childCompList}
                    onReceiptsFieldChange={(key, value)=>this.onReceiptsFieldChange(key, value)}
                    initCallBack={(frm)=>{
						ReportForm=frm; 
                    }} fromdate={this.state.fromDate} todate={this.state.toDate}
                    data={this.props.receiptReport.receiptDetails||[]}
                    cols={receiptcols}/>
                     <ReceiptsTable 
                     data={ sortedSummaryRows.concat(sortedTotalsRows)}
                    cols={receiptCol1}
                    />
                    <ReceiptsTable 
                    data={this.props.receiptReport.receiptDetails||[]}
                    cols={receiptcols}
                    />
                </div>
                <iframe title="Receipts-print" id="ifmcontentstoprint" 
                style={{height: '0px', width: '0px', position: 'absolute', fontSize:'7pt'}} media="print">

                </iframe>
            </Fragment>
		);
	}
}
const mapStateToProps = (state, ownProps) => {
	return {
        refNotify: state.refNotify,
        accountList: state.invoiceInfo.accountList,
        childCompList: state.searchInvoiceList.childComList,
        receiptReport:state.auditObj.receiptReport||{}
	}
};

export default withRouter(connect(mapStateToProps)(Receipts));
