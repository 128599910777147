import React, { Component, Fragment, useState } from 'react';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import Payment from '../payment/addEditPayment'
import { Modal } from 'react-bootstrap';
import { float, getCompanyId } from '../../../util/customMethods'
import ReactSelect from '../../../components/utilComponents/tmSlimSelect/reactSelect';
import { calculateLaborCharge } from '../../../util/customMethods';

const EstimateModal = ({ show, onClose, onSubmit, ratePlanData, pickupToDropoffTime, estimatedLabourCharge }) => {
    const [estimateValue, setEstimateValue] = useState('');
    const [hourlyCharge, setHourlyCharge] = useState(0);

    const handleValueChange = (e) => {
        const inputValue = e.target.value;
        setEstimateValue(inputValue);

        // Calculate hourly charge
        const pickupToDropoffHours = Math.max(0, Number(pickupToDropoffTime || 0) / 60);
        const inputHours = Math.max(0, Number(inputValue || 0));
        const totalHours = inputHours + pickupToDropoffHours;

        const calculatedCharge = calculateLaborCharge(ratePlanData, totalHours);
        setHourlyCharge(calculatedCharge);
    };

    return (
        <Modal show={show}>
            <div className="modal-content p-3">
                <h4 className="mb-3">Estimate remaining Hours</h4>

                <div className="form-group mb-3">
                    <label className="form-label">
                        Estimated Time Remaining: minutes
                    </label>
                    <label className="d-block">
                        Estimated Hourly Total: ${estimatedLabourCharge?.toFixed(2)}
                    </label>
                </div>

                <div className="form-group mb-4">
                    <label className="form-label">Additional Estimated Hours:</label>
                    <input
                        type="number"
                        className="form-control"
                        value={estimateValue}
                        onChange={handleValueChange}
                        min="0"
                        step="0.5"
                    />
                </div>

                <div className="text-center">
                    <button
                        className="btn btn-secondary me-2"
                        onClick={onClose}
                    >
                        Close
                    </button>
                    <button
                        className="btn btn-primary"
                        onClick={() => onSubmit({ estimateValue, hourlyCharge })}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </Modal>
    );
};

// ConfirmModal.js
const ConfirmModal = ({ show, onClose, onConfirm }) => (
    <Modal show={show}>
        <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title">Confirm Payment</h5>
            </div>
            <div className="modal-body">
                <p>Are you sure you want to confirm this payment?</p>
            </div>
            <div className="modal-footer">
                <button className="btn btn-secondary" onClick={onClose}>Cancel</button>
                <button className="btn btn-primary" onClick={onConfirm}>Confirm</button>
            </div>
        </div>
    </Modal>
);

// CancelModal.js
const CancelModal = ({ show, onClose, onCancel }) => (
    <Modal show={show}>
        <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title">Cancel Payment</h5>
            </div>
            <div className="modal-body">
                <p>Are you sure you want to cancel this payment?</p>
            </div>
            <div className="modal-footer">
                <button className="btn btn-secondary" onClick={onClose}>Close</button>
                <button className="btn btn-danger" onClick={onCancel}>Cancel Payment</button>
            </div>
        </div>
    </Modal>
);


class Billing extends Component {
    state = {
        openCommWindow: false,
        paymentLocation: '',
        paymentType: '',
        chequeNo: '',

        showEstimate: false,
        showConfirm: false,
        showCancel: false,
        estimateValue: ''
    }

    handleEstimate = () => {
        // API call or business logic for estimate
        this.props.onEstimate(this.state.estimateValue);
        this.setState({ showEstimate: false });
    }

    handleConfirm = () => {
        // API call or business logic for confirmation
        this.props.onConfirmPayment();
        this.setState({ showConfirm: false });
    }

    handleCancel = (invoiceId) => {
        if (!invoiceId) return;

        // Call the API
        this.props.onCancelPayment(invoiceId)

        this.setState({ showCancel: false });
    }
    handleValueChange = (e) => {
        const pickupToDropoffHours = Math.max(0, Number(this.state.pickupToDropoffTime || 0) / 60);
        const inputHours = Math.max(0, Number(e.target.value || 0));
        const totalHours = inputHours + pickupToDropoffHours;

        const hourlyCharge = calculateLaborCharge('', 0);

        this.setState({ estimatedHourlyTow: hourlyCharge });
    };
    createUI(charges, chargeOptionBilling, flag) {
        let chargeOption = [];
        chargeOption = chargeOptionBilling;
        let chargeArray = charges.split('|');
        chargeArray = chargeArray.filter(v => v != '');
        return (chargeArray || []).map((el, i) =>
            <div className="row form-group" key={i}>
                <label className="col-md-12 form-control-label" htmlFor="advancedPayout"></label>
                <div className="col-sm-5">
                    <select className="form-control" disabled={flag} name="chargeName" value={el.split(',')[0]} onChange={(e) => { this.props.manipulateCustomCharge(i, 0, e.target.value) }} >
                        {chargeOption.findIndex((item) => item.optionText === el.split(',')[0]) == -1 ? chargeOption.push({ optionText: el.split(',')[0], optionVal: 0 }) : null}
                        <option value=''>SELECT</option>
                        {chargeOption.map((item, i) =>
                            <option key={i} value={item.optionText}>{item.optionText}</option>
                        )}
                    </select>
                </div>
                <div className="col-sm-5">
                    <div className="input-groups">
                        <div className="input-groups-icon"><i className="fa fa-usd"></i></div>
                        <div className="input-groups-area">
                            <input
                                type="number" className="form-control" disabled={flag}
                                name="chargeAmt" value={el.split(',')[1]} onChange={(e) => { this.props.manipulateCustomCharge(i, 1, e.target.value) }}
                            />
                        </div>
                    </div>
                </div>
                <span className="col-sm-2">
                    <button title="Remove" name="charges" className="btn btn-sm btn-danger" disabled={flag} onClick={(e) => {
                        e.preventDefault();
                        this.props.deleteCustomCharge(i)
                    }}
                    >
                        <i class="fa fa-close" aria-hidden="true"></i>
                    </button>
                </span>
            </div>
        )
    }
    render() {
        const { _invoiceObj, accountList, accountReqData, billingCommission } = this.props.invoiceInfo || {};
        const { invoiceBilling, invoicePayments } = _invoiceObj;
        const ratePlan = invoiceBilling.ratePlan || {};
        const invoiceLock = invoiceBilling.isVerifiedAndLocked == 1 ? this.props.invoicePermission.edit : this.props.lockaccess.add;
        const isDisabled = (!(this.props.invoicePermission.edit || false)) || (invoiceBilling.isVerifiedAndLocked == 1);
        const childDisable = (_invoiceObj.callStatus == 'Cancel') || (_invoiceObj.isDispatchCleared == 1)
        console.log(".......................................................",ratePlan)
        return (<Fragment>
            <div className="pd-10">
                <Form ref={c => {
                    this.formBillngInfo = c;
                    this.props.initCallBack(c);
                }}>
                    <fieldset disabled={(_invoiceObj.callStatus || '').toUpperCase() == 'CANCEL'}>
                        <h6 className="text-blue text-bg-color form-group">Charges</h6>
                        <div className="row">
                            <div className="col">
                                <div class="card box-shadow">
                                    <div class="card-body" style={{ padding: '0.5rem' }}>
                                        <form>
                                            <div className="row">
                                                <div className="col">
                                                    <div className="">
                                                        <label htmlFor="accountName" className="form-label col-form-label">ACCOUNT NAME</label>
                                                        <div className="">
                                                            <Input type="text" disabled className="form-control form-control-sm"
                                                                name="accountId" value={(accountList.find((e) => e.optionVal == (_invoiceObj.accountId || '').toString()) || {}).optionText}
                                                            />
                                                        </div>
                                                        <label htmlFor="accountName" className="form-label col-form-label">PAYMENT ACCEPTED: {accountReqData.paymentType || 'NONE'}</label>
                                                    </div>
                                                </div>

                                                <div className="col">
                                                    <div className="">
                                                        <label htmlFor="accountName" className="form-label col-form-label">BILLING TO</label>
                                                        <div className="">
                                                            <ReactSelect name="billingTo"
                                                                closeMenuOnSelect={true}
                                                                notAmultiSelect={true}
                                                                isDisabled={isDisabled || childDisable}
                                                                options={accountList.filter(item => item.idCompany == getCompanyId()).map((item, t) => {
                                                                    return ({ value: item.optionVal, label: item.optionText })
                                                                })}
                                                                value={{
                                                                    value: ((invoiceBilling.billingTo || '').toString()),
                                                                    label: (accountList.find((e) => parseInt(e.optionVal) == invoiceBilling.billingTo || '') || {}).optionText
                                                                }}
                                                                onChange={(selectedOption) => {
                                                                    this.props.serviceCallInfoFieldChange('invoiceBilling', 'billingTo', parseInt(selectedOption.value));
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col">
                                                    <div className="">
                                                        <label htmlFor="accountName" className="form-label col-form-label">ACCOUNT RATE PLAN</label>
                                                        <div className="">
                                                            <ReactSelect name="accountRatePlanId"
                                                                closeMenuOnSelect={true}
                                                                isDisabled={isDisabled || childDisable}
                                                                notAmultiSelect={true}
                                                                options={(accountReqData.ratePlanOptions || []).map((item, t) => {
                                                                    return ({ value: item.optionVal, label: item.optionText })
                                                                })}
                                                                value={{
                                                                    value: (_invoiceObj.accountRatePlanId), label: ((accountReqData.ratePlanOptions || [])
                                                                        .find((e) => e.optionVal == _invoiceObj.accountRatePlanId) || '').optionText || ''
                                                                }}
                                                                onChange={(selectedOption) => {
                                                                    this.props.serviceCallInfoFieldChange('', 'accountRatePlanId', parseInt(selectedOption.value))
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-10">
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col">
                                        <div className="">
                                            <label htmlFor="accountName" className="form-label col-form-label">HOURLY RATE</label>
                                            <div className="input-groups">
                                                <div class="input-groups-icon Billing-HourlyTow">$</div>
                                                <div class="input-groups-area">
                                                    <Input type="text" disabled className="form-control form-control-sm Billing-HourlyTow" name="service_hourly_rate" value={float(ratePlan.service_hourly_rate)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="">
                                            <label htmlFor="accountName" className="form-label col-form-label">BILLABLE HOURS</label>
                                            <div className="">
                                                <Input type="text" disabled className="form-control form-control-sm Billing-HourlyTow" value={invoiceBilling.serviceBillableHours}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="">
                                            <label htmlFor="accountName" className="form-label col-form-label">UNLOADED MILES</label>
                                            <div className="">
                                                <Input type="number" className="form-control form-control-sm Billing-MileageCharge"
                                                    name="unloadedMiles" value={invoiceBilling.unloadedMiles} steps='0.1' disabled={isDisabled || childDisable}
                                                    onChange={(e) => {
                                                        this.props.mileageManualCal(e.target.name, e.target.value)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="">
                                            <label htmlFor="accountName" className="form-label col-form-label">RATE</label>
                                            <div className="input-groups">
                                                <div class="input-groups-icon Billing-MileageCharge">$</div>
                                                <div class="input-groups-area">
                                                    <Input type="number" className="form-control form-control-sm Billing-MileageCharge"
                                                        name="unloadedMilesRate" value={invoiceBilling.unloadedMilesRate} steps='0.1' disabled={isDisabled || childDisable}
                                                        onChange={(e) => {
                                                            this.props.mileageManualCal(e.target.name, e.target.value)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="">
                                            <label htmlFor="accountName" className="form-label col-form-label">LOADED MILES</label>
                                            <div className="">
                                                <Input type="number" className="form-control form-control-sm Billing-MileageCharge"
                                                    name="loadedMiles" value={invoiceBilling.loadedMiles} steps='0.1' disabled={isDisabled || childDisable}
                                                    onChange={(e) => {
                                                        this.props.mileageManualCal(e.target.name, e.target.value)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="">
                                            <label htmlFor="accountName" className="form-label col-form-label">RATE</label>
                                            <div className="input-groups">
                                                <div class="input-groups-icon Billing-MileageCharge">$</div>
                                                <div class="input-groups-area">
                                                    <Input type="text" className="form-control form-control-sm Billing-MileageCharge"
                                                        name="loadedMilesRate" value={invoiceBilling.loadedMilesRate} disabled={isDisabled || childDisable} onChange={(e) => {
                                                            this.props.mileageManualCal(e.target.name, e.target.value)
                                                        }}
                                                        steps='0.1'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="">
                                            <label htmlFor="accountName" className="form-label col-form-label">CALENDAR DAYS</label>
                                            <div className="">
                                                <Input type="text" className="form-control form-control-sm" disabled value={float(invoiceBilling.calendarDays)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="">
                                            <label htmlFor="accountName" className="form-label col-form-label">BILLABLE DAYS</label>
                                            <div className="">
                                                <Input type="text" className="form-control form-control-sm Billing-StorageCharge" disabled placeholder={float(invoiceBilling.paidNoOfDays)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="">
                                            <label htmlFor="accountName" className="form-label col-form-label">DAILY STORAGE RATE</label>
                                            <div className="input-groups">
                                                <div class="input-groups-icon Billing-StorageCharge">$</div>
                                                <div class="input-groups-area">
                                                    <Input type="text" className="form-control form-control-sm Billing-StorageCharge" disabled value={float(invoiceBilling.storageAfterLimitCharge)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="">
                                            <label htmlFor="accountName" className="form-label col-form-label">PO#</label>
                                            <div className="">
                                                <Input type="text" className="form-control form-control-sm" name="pONo" value={invoiceBilling.pONo} disabled={isDisabled || childDisable}
                                                    onChange={(e) => {
                                                        this.props.serviceCallInfoFieldChange("invoiceBilling", e.target.name, e.target.value)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="">
                                            <label htmlFor="accountName" className="form-label col-form-label">SERVICE ADVISOR NAME</label>
                                            <div className="">
                                                <Input type="text" className="form-control form-control-sm"
                                                    name="serviceAdvisorName" value={invoiceBilling.serviceAdvisorName || ''} disabled={isDisabled || childDisable}
                                                    onChange={(e) => {
                                                        this.props.serviceCallInfoFieldChange("invoiceBilling", e.target.name, e.target.value)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-10">
                                    <div className="col">
                                        <div className="">
                                            <label htmlFor="accountName" className="form-label col-form-label">REPAIR ORDER #</label>
                                            <div className="">
                                                <Input type="text" className="form-control form-control-sm"
                                                    name="repairOrderNo" value={invoiceBilling.repairOrderNo || ''} disabled={isDisabled || childDisable}
                                                    onChange={(e) => {
                                                        this.props.serviceCallInfoFieldChange("invoiceBilling", e.target.name, e.target.value)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-10">
                                    <div className="col-sm-4">
                                        <div className="custom-control custom-control custom-checkbox">
                                            <input type="checkbox" className="custom-control-input" disabled={isDisabled}
                                                name="isPoliceHold" id="isPoliceHold" checked={(invoiceBilling.isPoliceHold || 0) == 1}
                                                onChange={(e) => {
                                                    this.props.serviceCallInfoFieldChange("invoiceBilling", e.target.name, (invoiceBilling.isPoliceHold || 0) == 1 ? 0 : 1)
                                                }} />
                                            <label htmlFor="isPoliceHold" className="custom-control-label">POLICE HOLD</label>
                                        </div>

                                    </div>
                                    <div className="col-sm-3">
                                        <div className="custom-control custom-control custom-checkbox">
                                            <input type="checkbox" className="custom-control-input" name="isVerifiedAndLocked" id="isVerifiedAndLocked" disabled={!invoiceLock}
                                                checked={(invoiceBilling.isVerifiedAndLocked || 0) == 1} onChange={(e) => {
                                                    this.props.serviceCallInfoFieldChange("invoiceBilling", e.target.name, (invoiceBilling.isVerifiedAndLocked || 0) == 1 ? 0 : 1)
                                                }} />
                                            <label htmlFor="isVerifiedAndLocked" className="custom-control-label">LOCKED</label>
                                        </div>

                                    </div>
                                    <div className="col-sm-3">
                                        <div className="custom-control custom-control custom-checkbox">
                                            <input type="checkbox" className="custom-control-input" disabled={isDisabled} name="verifiedForReview" id="verifiedForReview" checked={(invoiceBilling.verifiedForReview || 0) == 1}
                                                onChange={(e) => {
                                                    this.props.serviceCallInfoFieldChange("invoiceBilling", e.target.name, (invoiceBilling.verifiedForReview || 0) == 1 ? 0 : 1)
                                                }} />
                                            <label htmlFor="verifiedForReview" className="custom-control-label">VERIFIED</label>
                                        </div>

                                    </div>
                                    <div className="col-sm-2 text-right">
                                        <button type="button" disabled={isDisabled} className="btn btn-info btn-sm pull-right" onClick={this.props.setReview}>
                                            <i className="fa fa-flag-o" aria-hidden="true"></i></button></div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                {/* <div className="row">
                                    <div className="col">
                                        <div className="">
                                            <label htmlFor="accountName" className="form-label col-form-label">FLAT DISCOUNT</label>
                                            <div className="input-groups">
                                                <div class="input-groups-icon">$</div>
                                                <div class="input-groups-area">
                                                    <Input type="number" className="form-control form-control-sm"
                                                        name="flatDiscount" value={invoiceBilling.flatDiscount || 0.00} steps='0.1' disabled={isDisabled || childDisable}
                                                        onChange={(e) => {
                                                            this.props.handleCharge(e.target.name, e.target.value)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="row">
                                    <div className="col">
                                        <div className="">
                                            <label htmlFor="accountName" className="form-label col-form-label">FLAT TOW</label>
                                            <div className="input-groups">
                                                <div class="input-groups-icon">$</div>
                                                <div class="input-groups-area">
                                                    <Input type="number" className="form-control form-control-sm"
                                                        name="towCharge" value={invoiceBilling.towCharge} steps='0.1' disabled={isDisabled || childDisable}
                                                        onChange={(e) => {
                                                            this.props.handleCharge(e.target.name, e.target.value)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="">
                                            <label htmlFor="accountName" className="form-label col-form-label">HOURLY TOW</label>
                                            <div className="input-groups">
                                                <div class="input-groups-icon Billing-HourlyTow">$</div>
                                                <div class="input-groups-area">
                                                    <Input type="text" className="form-control form-control-sm Billing-HourlyTow" disabled
                                                        name="laborCharge" value={float(invoiceBilling.laborCharge)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="">
                                            <label htmlFor="accountName" className="form-label col-form-label">MILEAGE CHARGE</label>
                                            <div className="input-groups">
                                                <div class="input-groups-icon Billing-MileageCharge">$</div>
                                                <div class="input-groups-area">
                                                    <Input type="text" className="form-control form-control-sm Billing-MileageCharge" disabled
                                                        name="accountName" value={float(invoiceBilling.mileageCharge)} requiredmsg="Please enter Account Name"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="">
                                            <label htmlFor="accountName" className="form-label col-form-label">STORAGE CHARGE</label>
                                            <div className="input-groups">
                                                <div class="input-groups-icon Billing-StorageCharge">$</div>
                                                <div class="input-groups-area">
                                                    <Input type="text" className="form-control form-control-sm Billing-StorageCharge" disabled
                                                        name="accountName" value={float(invoiceBilling.storageCharge)} requiredmsg="Please enter Account Name"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="">
                                            <label htmlFor="accountName" className="form-label col-form-label">GATE CHARGE</label>
                                            <div className="input-groups">
                                                <div class="input-groups-icon">$</div>
                                                <div class="input-groups-area">
                                                    <Input type="number" className="form-control form-control-sm"
                                                        name="gateCharge" value={invoiceBilling.gateCharge} steps='0.1' disabled={isDisabled || childDisable}
                                                        onChange={(e) => { this.props.handleCharge(e.target.name, e.target.value) }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-10">
                                    <div className="col">
                                        <div className="">
                                            <label htmlFor="accountName" className="form-label col-form-label">ADVANCED PAYOUT</label>
                                            <div className="input-groups">
                                                <div class="input-groups-icon">$</div>
                                                <div class="input-groups-area">
                                                    <Input type="number" className="form-control form-control-sm"
                                                        name="advancedPayout" value={(invoiceBilling.advancedPayout)} steps='0.1' disabled={isDisabled || childDisable}
                                                        onChange={(e) => { this.props.handleCharge(e.target.name, e.target.value) }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <Input type="text" className="form-control form-control-sm" placeholder="MISC. CHARGE NAME"
                                            name="miscChargeLabel" value={invoiceBilling.miscChargeLabel || ''} disabled={isDisabled || childDisable}
                                            onChange={(e) => { this.props.serviceCallInfoFieldChange("invoiceBilling", e.target.name, e.target.value) }}
                                        />
                                    </div>
                                </div>
                                <div className="row mb-10">
                                    <div className="col">
                                        <div className="">
                                            <label htmlFor="accountName" className="form-label col-form-label">MISC. CHARGE</label>
                                            <div className="input-groups">
                                                <div class="input-groups-icon">$</div>
                                                <div class="input-groups-area">
                                                    <Input type="number" className="form-control form-control-sm"
                                                        name="miscCharge" value={invoiceBilling.miscCharge} steps='0.1' disabled={isDisabled || childDisable}
                                                        onChange={(e) => { this.props.handleCharge(e.target.name, e.target.value) }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <input type="button" className="btn btn-sm btn-success" name="addCustomCharge" value={'Add Custom Charge'} onClick={this.props.addCutomCharge} disabled={isDisabled} />
                                    </div>
                                </div>
                                <div className="row mb-10">
                                    <div className="col">
                                        <div className="">
                                            <div className="">
                                                {invoiceBilling.userDefined_Charge != '' ? this.createUI(invoiceBilling.userDefined_Charge, invoiceBilling.customChargesOptions || [], isDisabled || childDisable) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div class="card box-shadow">
                                            <div class="card-body" style={{ padding: '0.5rem' }}>
                                                <form>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="">
                                                                <label htmlFor="accountName" className="form-label col-form-label">TAX</label>
                                                                <div className="input-groups">
                                                                    <div class="input-groups-icon">$</div>
                                                                    <div class="input-groups-area">
                                                                        <Input type="number" className="form-control form-control-sm"
                                                                            name="tax" value={(invoiceBilling.tax)} steps='0.1' disabled={isDisabled || childDisable}
                                                                            onChange={(e) => { this.props.handleCharge(e.target.name, e.target.value) }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="">
                                                                <label htmlFor="accountName" className="form-label col-form-label">SUB TOTAL</label>
                                                                <div className="input-groups">
                                                                    <div class="input-groups-icon">$</div>
                                                                    <div class="input-groups-area">
                                                                        <Input type="text" className="form-control form-control-sm" disabled
                                                                            name="accountName" value={float(invoiceBilling.totalCharge)} requiredmsg="Please enter Account Name"
                                                                            onChange={(e) => {
                                                                                this.props.serviceCallInfoFieldChange("invoiceBilling", e.target.name, e.target.value)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="">
                                                                <label htmlFor="accountName" className="form-label col-form-label">DISCOUNT</label>
                                                                <div className="input-groups">
                                                                    <div class="input-groups-icon">$</div>
                                                                    <div class="input-groups-area">
                                                                        <Input type="text" className="form-control form-control-sm"
                                                                            disabled={isDisabled || childDisable}
                                                                            onChange={(e) => {
                                                                                this.props.handleCharge(e.target.name, e.target.value)
                                                                            }}
                                                                            name="flatDiscount" value={float(invoiceBilling.flatDiscount)} requiredmsg="Please enter Account Name"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="">
                                                                <label htmlFor="accountName" className="form-label col-form-label">PAYMENTS</label>
                                                                <div className="input-groups">
                                                                    <div class="input-groups-icon">$</div>
                                                                    <div class="input-groups-area">
                                                                        <Input type="text" className="form-control form-control-sm" disabled
                                                                            name="accountName" value={float(invoiceBilling.paymentAmt)} requiredmsg="Please enter Account Name"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="">
                                                                <label htmlFor="accountName" className="form-label col-form-label">BALANCE DUE</label>
                                                                <div className="input-groups">
                                                                    <div class="input-groups-icon">$</div>
                                                                    <div class="input-groups-area">
                                                                        <Input type="text" className="form-control form-control-sm" disabled
                                                                            name="balanceAmt" value={float(invoiceBilling.balanceAmt)} requiredmsg="Please enter Account Name"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                {invoiceBilling.enbaleRecalculate &&
                                                                    <div className="col-12 text-center mt-4">
                                                                        <div className="input-group justify-content-center">
                                                                            <button
                                                                                onClick={() => this.setState({ showEstimate: true })}
                                                                                className="btn btn-success"
                                                                                type="button"
                                                                                title="confirm"
                                                                            >
                                                                                {'Estimate'} <i className="fa fa-check"></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {!invoiceBilling.enbaleRecalculate && invoiceBilling.isPaymentConfirmed &&
                                                                    <div className="col-12 text-center mt-4">
                                                                        <div className="input-group justify-content-center">
                                                                            <button
                                                                                onClick={() => this.setState({ showConfirm: true })}
                                                                                className="btn btn-warning"
                                                                                type="button"
                                                                                title="confirm"
                                                                            >
                                                                                {'Confirm Payment'} <i className="fa fa-check"></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {invoiceBilling.confirmedByDispatcher &&
                                                                    <div className="col-12 text-center mt-4">
                                                                        <div className="input-group justify-content-center">
                                                                            <button
                                                                                onClick={() => this.setState({ showCancel: true })}
                                                                                className="btn btn-danger"
                                                                                type="button"
                                                                                title="confirm"
                                                                            >
                                                                                {'cancel Payment'} <i className="fa fa-check"></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>

                                                </form>

                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </fieldset>
                    <Payment paymentAccess={this.props.paymentAccess} invoicePayments={invoicePayments || []} invoiceId={this.props.invoiceInfo._invoiceObj.invoiceId} locationList={this.props.reviewLocation || []}
                        isDisabled={isDisabled} addUpdatePayments={(invoiceId, obj) => this.props.addUpdatePayments(invoiceId, obj)} deletePayments={(id, invoice) => { this.props.deletePayments(id, invoice) }} />
                </Form>
                <Modal show={this.state.openCommWindow}>
                    <div className="modal-body">
                        <h2>Commission</h2>
                        <div className="row">
                            <div className="col-6">
                                <label htmlFor="driverId">Driver ID:</label>
                            </div>
                            <div className="col-6">
                                <input className="form-control" type='text' value={_invoiceObj.driverId} disabled />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-1" style={{ "display": "relative", "float": "right" }}>
                                <input type="checkbox" id="loadDefaultCommission" disabled={isDisabled || (!this.props.commissionaccess.edit)}
                                    onChange={(e) => { this.props.getInvoiceComm(e.target.checked || false); }} />
                            </div>
                            <div className="col-11">
                                <label htmlFor="loadDefaultCommission">{"Load Default Commission"}</label>
                            </div>
                        </div>
                        <br />
                        <h5>Commission Rates</h5>
                        <div className="row">
                            <div className="col-6">
                                <label htmlFor="flatRate">{'Flat Rate '}</label>
                            </div>
                            <div className="col-6">
                                <input
                                    type="number" className="form-control" value={billingCommission.invoiceCommFlatRate}
                                    disabled={isDisabled || (!this.props.commissionaccess.edit)} onChange={(e) => { this.props.calculateComm('invoiceCommFlatRate', e.target.value) }} />
                            </div>
                            <br /><br />
                            <div className="col-6">
                                <label htmlFor="comHoursRate">{'Hourly Rate '}</label>
                            </div>
                            <div className="col-6">
                                <input type="number" value={billingCommission.invoiceCommHourlyRate} className="form-control"
                                    disabled={isDisabled || (!this.props.commissionaccess.edit)} onChange={(e) => { this.props.calculateComm('invoiceCommHourlyRate', e.target.value) }} />
                            </div>
                            <br /><br />
                            <div className="col-6">
                                <label htmlFor="billableHours">{'# Hours '}</label>
                            </div>
                            <div className="col-6">
                                <input className="form-control" type="text" value={billingCommission.invoiceCommHours || '00:00'} disabled />
                            </div>
                            <br /><br />
                            <div className="col-6">
                                <label htmlFor="hourlyRate">{'Hourly Commission '}</label>
                            </div>
                            <div className="col-6">
                                <input type="number" readOnly disabled value={billingCommission.commissionHourlyRate}
                                    className="form-control" />
                            </div>
                            <br /><br />
                            <div className="col-6">
                                <label htmlFor="commissionPercent">{'Percent '}</label>
                            </div>
                            <div className="col-6">
                                <input type="number" value={billingCommission.invoiceCommPercentage} className="form-control"
                                    disabled={isDisabled || (!this.props.commissionaccess.edit)} onChange={(e) => { this.props.calculateComm('invoiceCommPercentage', e.target.value) }} />
                            </div>
                            <br /><br />
                            <div className="col-6">
                                <label htmlFor="minPerCall">{'Minimum Pay Per Call '}</label>
                            </div>
                            <div className="col-6">
                                <input type="number" value={billingCommission.invoiceCommMinPayPerCall} className="form-control"
                                    disabled={isDisabled || (!this.props.commissionaccess.edit)} onChange={(e) => { this.props.calculateComm('invoiceCommMinPayPerCall', e.target.value) }} />
                            </div>
                            <br /><br />
                            <div className="col-6">
                                <label htmlFor="commissionRate">{'Commission Rate '}</label>
                            </div>
                            <div className="col-6">
                                <input type="number" disabled value={billingCommission.commission} className="form-control" />
                            </div><br /><br />
                        </div>
                        <div>
                            <button className="btn btn-success" disabled={isDisabled || (!this.props.commissionaccess.edit)} onClick={(e) => { this.props.saveInvoiceComm(); this.setState({ openCommWindow: false }) }} >Save</button>{' '}
                            <button className="btn btn-danger" onClick={() => { this.setState({ openCommWindow: false }) }}>Close</button>
                        </div>
                    </div>
                </Modal>
                <EstimateModal
                    show={this.state.showEstimate}
                    onClose={() => this.setState({ showEstimate: false })}
                    onSubmit={({ estimateValue, hourlyCharge }) => {
                        const updates = {
                            estimatedminuts: estimateValue,
                            estimatedLabourCharge: hourlyCharge,
                            storageCharge: hourlyCharge,
                            enbaleRecalculate: 0,
                            serviceBillableHours: estimateValue
                        };
                    
                        // Handle all updates in a single batch
                        Object.entries(updates).forEach(([key, value]) => {
                            this.props.handleCharge(key, value);
                        });
                    
                        this.setState({ showEstimate: false });
                    }}
                    ratePlanData={ratePlan}
                    pickupToDropoffTime={invoiceBilling?.estimatedminuts}
                    estimatedLabourCharge={invoiceBilling?.estimatedLabourCharge}
                />
                <ConfirmModal
                    show={this.state.showConfirm}
                    onClose={() => this.setState({ showConfirm: false })}
                    onConfirm={()=>{this.props.updateInvoiceBilling(invoiceBilling?.estimatedminuts,invoiceBilling.invoiceId,invoiceBilling?.estimatedLabourCharge)}}
                />
                <CancelModal
                    show={this.state.showCancel}
                    onClose={() => this.setState({ showCancel: false })}
                    onCancel={() => { this.handleCancel(_invoiceObj.invoiceId) }}
                />
            </div>
        </Fragment>);
    }
}
export default Billing
