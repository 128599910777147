import React, { Component, Fragment } from 'react';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import Select from 'react-validation/build/select';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import { GlobalValues, priorities } from '../../../util/constants';
import { longName, isManagement, getCompanyId } from '../../../util/customMethods'
import ReactSelect from '../../../components/utilComponents/tmSlimSelect/reactSelect';
import AddressEntry from '../../adminComponents/account/defaultInfo/addressEntry';
import TmDateTimePicker from '../utilDispatch/tmDateTimePicker/tmDateTimePicker';
import { ImageViewer } from "react-image-viewer-dv"
import {
    required,
    email,
    phone
} from "../../../util/validationMethods";
let address = {};
class ServiceCallInfo extends Component {
    state = {
        invoiceid: null,
        noOfDuplicates: [1, 2, 3, 4, 5],
        serviceCallInfo: {},
        showAccountDefaultsModal: false,
        subInvoiceModal: false,
        openAhrmodel: false,
        tempAccountId: 0,
        textVal: '',
        ahrType: 'propertyRelease',
        ahrGateFee: 0.00,
        clickAllowed: true
    }

    handleAHRTypeChange = (e) => {
        this.setState({ ahrType: e.target.value });
    };
    
    handleGateFeeChange = (e) => {
        const value = e.target.value;
        
        // Allow empty string, numbers, and decimal points during typing
        if (value === '' || /^\d*\.?\d{0,2}$/.test(value)) {
            this.setState({ ahrGateFee: value });
        }
    };

    sessionAhrIInvoice = () => {
        const { ahrType, ahrGateFee } = this.state;
        
        // Disable the button to prevent double submission
        this.setState({ clickAllowed: false });
    
        // Convert to float only when submitting
        const gateFeeFloat = parseFloat(ahrGateFee) || 0.00;
    
        // Call the parent callback with AHR details
        this.props.handleAHRInvoice('AHR', 
            ahrType,
            gateFeeFloat
        );
    
        // Reset the form and close the modal
        this.setState({
            ahrType: 'propertyRelease',
            ahrGateFee: '0.00',  // Reset as string for better input handling
            openAhrmodel: false,
            clickAllowed: true
        });
    };
    render() {
        const { _invoiceObj, accountList, accountReqData, accountListByType } = this.props.invoiceInfo || {};
        const { serviceCallInfo, eligibilityStatus } = _invoiceObj || {};
        const invoiceBilling = _invoiceObj.invoiceBilling || {};
        const isDisabled = (!(this.props.invoicePermission.edit || false)) || (invoiceBilling.isVerifiedAndLocked == 1) || (_invoiceObj.callStatus == 'Cancel');
        const childDisable = !isManagement();
        return (<Fragment>
            <div className="pd-10">
                <Form ref={c => {
                    this.formServiceCallInfo = c;
                    this.props.initCallBack(c);
                }} className="">
                    <fieldset disabled={isDisabled}>
                        <div className="row">
                            <div className="col-md-4">
                                <h6 className="text-blue text-bg-color form-group">Account Details</h6>
                                {(!this.props.dispatchReview && isManagement()) ?
                                    <span><div className="mb-5 row">
                                        <div className="col-sm-12">
                                            <h6>SERVICE CALL TYPE:</h6>
                                        </div>
                                    </div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group row">
                                                    <div className="col-sm-12">
                                                        <div className="custom-control custom-control custom-radio">
                                                            <input type="radio" className="custom-control-input" checked={serviceCallInfo.serviceCallType === 'regular'} name="serviceCallType" id="rdBillable" />
                                                            <label htmlFor="rdBillable" className="custom-control-label">REGULAR</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group row">
                                                    <div className="col-sm-12">
                                                        <div className="custom-control custom-control custom-radio">
                                                            <input type="radio" className="custom-control-input" name="serviceCallType" id="rdCash" value="Cash" />
                                                            <label htmlFor="rdCash" className="custom-control-label">COMPLETED</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div></span> : null}
                                {!this.props.dispatchReview ? <span>
                                    <div className="row">
                                        <div className="col-sm-5">
                                            <div className="form-group row">
                                                <div className="col-sm-12">
                                                    <div className="custom-control custom-checkbox custom-control">
                                                        <input type="checkbox" name='duplicateCopyCount' id="duplicate" className="custom-control-input" checked={(_invoiceObj.duplicateCopyCount || 0) !== 0}
                                                            onChange={(e) => {
                                                                this.props.serviceCallInfoFieldChange("", e.target.name, (_invoiceObj.duplicateCopyCount || 0) == 0 ? 1 : 0)
                                                            }} />
                                                        <label htmlFor="duplicate" className="custom-control-label">DUPLICATE</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-7">
                                            <Select className="form-control form-control-sm" name="duplicateCopyCount" value={_invoiceObj.duplicateCopyCount || 0}
                                                disabled={(_invoiceObj.duplicateCopyCount || 0) === 0} onChange={(e) => {
                                                    this.props.serviceCallInfoFieldChange("", e.target.name, e.target.value)
                                                }}>
                                                <option value={""}>{"select"}</option>
                                                {this.state.noOfDuplicates.map((noOfDuplicates, key) => { return (<option key={noOfDuplicates} value={noOfDuplicates}>{noOfDuplicates}</option>); })}
                                            </Select>

                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                        <div className="col-md-4">
                                                <input type="button" hidden={!eligibilityStatus?.isAhrEligible || isManagement()} class="btn btn-sm btn-primary pull-right" value="Ahr"
                                                    onClick={e => { this.setState({ openAhrmodel: true, textVal: 'AHR' });}} />
                                            </div>
                                            <div className="col-md-4">
                                                <input type="button" hidden={!eligibilityStatus.isRehookOrTowbackElig || isManagement()} class="btn btn-sm btn-primary pull-right" value="Rehook"
                                                    onClick={e => { this.setState({ subInvoiceModal: true, textVal: 'Rehook' }); this.props.fetchAccountByType('Rehook') }} />
                                            </div>
                                            {/* <div className="col-md-4">
                                                <input type="button" hidden={eligibilityStatus.isRehookOrTowbackElig || !(isManagement())} class="btn btn-sm btn-primary pull-right" value="Towback"
                                                    onClick={e => {
                                                        this.setState({ subInvoiceModal: true, textVal: 'Towback' });
                                                        this.props.fetchAccountByType('Towback')
                                                    }}

                                                />
                                            </div> */}
                                            <div className="col-md-4">
                                                <input type="button" hidden={!eligibilityStatus.isSalvageElig || isManagement()} class="btn btn-sm btn-primary pull-right" value="Salvage" />
                                            </div>
                                        </div>
                                    </div>
                                    {isManagement() ? <span><div className="row">
                                        <div className="col-sm-12">
                                            <div className="form-group row">
                                                <div className="col-sm-12">
                                                    <div className="custom-control custom-checkbox custom-control">
                                                        <input type="checkbox" className="custom-control-input"
                                                            onChange={(e) => {
                                                                this.props.serviceCallInfoFieldChange("", e.target.name, e.target.checked)
                                                            }}
                                                            checked={_invoiceObj.disableAutoAssign} name="disableAutoAssign" id="disable_Auto_Assign" />
                                                        <label htmlFor="disable_Auto_Assign" className="custom-control-label">DISABLE AUTO ASSIGN</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="form-group row">
                                                    <div className="col-sm-12">
                                                        <div className="custom-control custom-checkbox custom-control">
                                                            <input type="checkbox" className="custom-control-input"
                                                                onChange={(e) => {
                                                                    this.props.serviceCallInfoFieldChange("", e.target.name, (_invoiceObj.callHold || 0) === 0 ? 1 : 0)
                                                                }}
                                                                checked={(_invoiceObj.callHold || 0) !== 0} name="callHold" id="scheduledcall" />
                                                            <label htmlFor="scheduledcall" className="custom-control-label">SCHEDULED CALL</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                       {(_invoiceObj.callHold || 0)==0?null :<div className="row">
                                            <div className="col">
                                                <div className="">
                                                    <label htmlFor="accountName" className="form-label col-form-label">DATE AND TIME</label>
                                                    <div >
                                                        <TmDateTimePicker
                                                            selectedDate={
                                                                (_invoiceObj.callHoldDate || "") === ""
                                                                    ? null
                                                                    : moment(moment(_invoiceObj.callHoldDate + ' ' + _invoiceObj.callHoldTime, GlobalValues.dateTimeFmtForDispatch)
                                                                        ,
                                                                        GlobalValues.dateTimeFormat
                                                                    )._d
                                                            }
                                                            disabled={(_invoiceObj.callHold || 0) === 0}
                                                            showTimeSelect={true} timeFormat='HH:mm'
                                                            onDateSelectChange={e => {
                                                                this.props.serviceCallInfoFieldChange('', 'callHoldDate', e === null ? '' : moment(e).format(GlobalValues.dispatcherDatefmt))
                                                                this.props.serviceCallInfoFieldChange('', 'callHoldTime', e === null ? '' : moment(e).format(GlobalValues.timeFormat))
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}
                                        </span> : null}
                                    <div className="row">
                                        <div className="col">
                                            <div className="">
                                                <label htmlFor="accountName" className="form-label col-form-label">INVOICE NUMBER</label>
                                                <div className="">
                                                    <Input type="text" className="form-control form-control-sm"
                                                        disabled name="invoiceId" value={_invoiceObj.displayInvoiceId || ''}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <div className="">
                                                <label htmlFor="accountName" className="form-label col-form-label">DATE</label>
                                                <div >
                                                    <TmDateTimePicker 
                                                        selectedDate={
                                                            (_invoiceObj.invoiceCreatedDate || "") === ""
                                                                ? null
                                                                : moment(moment(_invoiceObj.invoiceCreatedDate).format(GlobalValues.dateFormat), GlobalValues.dateFormat)._d
                                                        }
                                                        onDateSelectChange={e => {
                                                            this.props.serviceCallInfoFieldChange('', 'invoiceCreatedDate', e === null ? '' : moment(e).format(GlobalValues.dateTimeFmtForDispatch))
                                                        }}
                                                        disabled={_invoiceObj.invoiceId || '' == ''}
                                                        dateFormat={GlobalValues.dateFormat}
                                                        showYearDropdown={true}
                                                        showmonthdropdown={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </span> : null}
                                <div className="row">
                                    <div className="col">
                                        <div className="">
                                            <label htmlFor="accountName" className="form-label col-form-label mr-2">ACCOUNT NAME</label>
                                            {this.props.dispatchReview ? null : <i class="fa fa-question-circle col-form-label" style={{ color: '#00bcf2' }} onClick={(e) => ((_invoiceObj.accountId || '').toString()) !== '' ? this.props.handleAccountNotesListOpen(e) : ''}></i>}
                                            <div style={{ position:"relative", zIndex:"9" }}>
                                                <ReactSelect name="accountId"
                                                    closeMenuOnSelect={true}  
                                                    isDisabled={!this.props.dispatchReview && (isDisabled || childDisable)}
                                                    notAmultiSelect={true}
                                                    options={accountList.filter(item => item.idCompany == getCompanyId()).map((item, t) => {
                                                        return ({ value: item.optionVal, label: item.optionText })
                                                    })}
                                                    value={{
                                                        value: ((_invoiceObj.accountId || '').toString()),
                                                        label: (accountList.find((e) => e.optionVal == (_invoiceObj.accountId || '').toString()) || {}).optionText
                                                    }}
                                                    onChange={(selectedOption) => {
                                                        if ((_invoiceObj.invoiceId || '') == "") {
                                                            this.props.serviceCallInfoFieldChange('', 'accountId', parseInt(selectedOption.value||0));
                                                            this.props.fetchReQAccountInfo(parseInt(selectedOption.value), '', 'regular', false);
                                                        }
                                                        else if(this.props.dispatchReview){
                                                            this.props.fetchReQAccountInfo(parseInt(selectedOption.value||0), '', 'regular', true);
                                                            this.props.serviceCallInfoFieldChange('', 'accountId', parseInt(selectedOption.value||0));
                                                            this.setState({tempAccountId: parseInt(selectedOption.value||0) })
                                                        }
                                                         else {
                                                            this.setState({ showAccountDefaultsModal: true, tempAccountId: parseInt(selectedOption.value||0) })
                                                        }
                                                    }}
                                                />
                                                <Input hidden={true} name='accountId' value={_invoiceObj.accountId || ''} validations={[required]} requiredmsg="Please Select Account Name."></Input>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="">
                                            <label htmlFor="accountName" className="form-label col-form-label mr-2">RATE PLAN</label>
                                            {this.props.dispatchReview ? null : <i class="fa fa-question-circle col-form-label" onClick={(e) => (_invoiceObj.accountRatePlanId || '') !== '' ? this.props.handleRatePlanListOpen(e) : ''} style={{ color: '#00bcf2' }}></i>}
                                            <div className="">
                                                <Select className="form-control form-control-sm" name="accountRatePlanId" disabled={!this.props.dispatchReview && (isDisabled || childDisable)} value={_invoiceObj.accountRatePlanId}
                                                    validations={[required]} requiredmsg="Please Select Account Rateplan."
                                                    onChange={(e) => {
                                                        this.props.serviceCallInfoFieldChange("", e.target.name, e.target.value)
                                                        if ((_invoiceObj.invoiceId || '') == "" || !_invoiceObj.accountRatePlanId != e.target.value)
                                                            this.props.defaultInvoiceInfo();
                                                    }}>
                                                    <option value={""}>{"select ratePlan"}</option>
                                                    {(accountReqData.ratePlanOptions || []).map((item, key) => { return (<option key={key} value={item.optionVal}>{item.optionText}</option>); })}
                                                </Select>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="">
                                            <label htmlFor="accountName" className="form-label col-form-label">REASON</label>
                                            <div className="">
                                                <Select name="reason" className="form-control form-control-sm"
                                                    validations={[required]} requiredmsg="Please Select Reason."
                                                    value={_invoiceObj.reason}
                                                    onChange={(e) => { this.props.serviceCallInfoFieldChange('', 'reason', e.target.value) }} >
                                                    <option value={""}>{"select reason"}</option>
                                                    {(accountReqData.reasonList || []).map((item, key) => { return (<option key={key} value={item}>{item}</option>); })}
                                                    {(_invoiceObj.reason == "Cancelled") ? <option key={'Cancelled'} value='Cancelled'>Cancelled</option> : null}
                                                </Select>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {!this.props.dispatchReview? <div className="row">
                                    <div className="col">
                                        <div className="">
                                            <label htmlFor="accountName" className="form-label col-form-label">PRIORITY</label>
                                            <div className="">
                                                <Select name="priorty" className="form-control form-control-sm"
                                                    validations={[required]} requiredmsg="Please Select Priorty."
                                                    value={_invoiceObj.priority}
                                                    onChange={(e) => { this.props.serviceCallInfoFieldChange('', 'priority', e.target.value) }} >
                                                    <option value={""}>{"select priorty"}</option>
                                                    {(Object.keys(priorities)).map((item, key) => { return (<option key={key} value={item}>{item}</option>); })}
                                                </Select>
                                            </div>
                                           
                                        </div>
                                    </div>
                                </div>:null}
                                {this.props.dispatchReview?
                                 <div className="row">
                                 <div className="col">
                                           <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <ImageViewer >
                                                        <img className='imageViewer' src={this.props.vPic} alt="Vehicle image" />
                                                    </ImageViewer>
                                                    </div>
                                                    </div>
                                            </div>:null}
                            </div>
                            <div className="col-md-8">
                                {!this.props.dispatchReview && isManagement() ? <h6 className="text-blue text-bg-color form-group" style={{ margin: '0' }}>Caller Details</h6> : null}
                                {!this.props.dispatchReview && isManagement() ? <div className="row mb-10">
                                    <div className="col">
                                        <div className="">
                                            <label htmlFor="accountName" className="form-label col-form-label">CALLER LIST</label>
                                            <div className="">
                                                <Select className="form-control form-control-sm" name="callerName"
                                                    value={serviceCallInfo.callerName}
                                                    onChange={(e) => { this.props.selectCallerInfo(e.target.value) }}
                                                >
                                                    <option value={""}>{"select Caller"}</option>
                                                    {(accountReqData.accountContacts || []).map((item, key) => { return (<option key={key} value={item.contact_Name}>{item.contact_Name}</option>); })}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="">
                                            <label htmlFor="accountName" className="form-label col-form-label">CALLER NAME</label>
                                            <div className="">
                                                <Input type="text" className="form-control form-control-sm"
                                                    name="callerName" value={serviceCallInfo.callerName || ''} validations={true ? [required] : []}
                                                    onChange={(e) => { this.props.serviceCallInfoFieldChange('serviceCallInfo', e.target.name, e.target.value) }}
                                                    requiredmsg="Please Enter Caller Name."
                                                />
                                            </div>
                                        </div>
                                        <div className="">
                                            <label htmlFor="accountName" className="form-label col-form-label">CALLER PHONE</label>
                                            <div className="">
                                                <Input type="text" className="form-control form-control-sm"
                                                    name="callerPhoneNo" value={serviceCallInfo.callerPhoneNo || ''}
                                                    onChange={(e) => { this.props.serviceCallInfoFieldChange('serviceCallInfo', e.target.name, e.target.value) }}
                                                    validations={[required, phone]} requiredmsg="Please Enter Caller Phone No."
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="">
                                            <label htmlFor="accountName" className="form-label col-form-label">CALLER EMAIL</label>
                                            <div className="">
                                                <Input type="text" className="form-control form-control-sm"
                                                    onChange={(e) => { this.props.serviceCallInfoFieldChange('serviceCallInfo', e.target.name, e.target.value) }}
                                                    name="callerEmailId" value={serviceCallInfo.callerEmailId || ''} validations={[email]}
                                                />
                                            </div>
                                        </div>
                                        <div className="">
                                            <label htmlFor="accountName" className="form-label col-form-label">CONTACT NAME</label>
                                            <div className="">
                                                <Input type="text" className="form-control form-control-sm"
                                                    onChange={(e) => { this.props.serviceCallInfoFieldChange('', e.target.name, e.target.value) }}
                                                    name="contactPerson" value={_invoiceObj.contactPerson} requiredmsg="Please Enter Account Name."
                                                />
                                            </div>
                                        </div>
                                        <div className="">
                                            <label htmlFor="accountName" className="form-label col-form-label">CONTACT PHONE</label>
                                            <div className="">
                                                <Input type="text" className="form-control form-control-sm"
                                                    onChange={(e) => { this.props.serviceCallInfoFieldChange('', e.target.name, e.target.value) }}
                                                    name="contactPhoneNo" value={_invoiceObj.contactPhoneNo} requiredmsg="Please Enter Account Name."
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div> : null}
                                <div className="row">
                                    <div className="col">
                                        <AddressEntry title="PICK-UP LOCATION" type={''}
                                            address={address = {
                                                location: _invoiceObj.pickUpLocation, addr1: _invoiceObj.pickUpAddr_Address_Line1,
                                                city: _invoiceObj.pickUpAddr_Address_City, state: longName(_invoiceObj.pickUpAddr_Address_State),
                                                zipCode: _invoiceObj.pickUpAddr_Address_Zip, landmark: _invoiceObj.pickUpAddr_Address_Landmark, latLong: _invoiceObj.pick_up_Lat_Lang
                                            }}
                                            isDispatch={true}
                                            reviewLocation={this.props.reviewLocation}
                                            allStates={this.props.allStates} onFieldValueChange={(key, value) => {
                                                this.props.onAddressChange('pickUp', key, value)
                                            }} onAddressObjByLocationChange={(addrObj) => {
                                                this.props.onAddressChange('pickUp', '', addrObj);
                                            }}
                                            reCalMileage={(e) => { this.props.reCalMileage(); }}
                                            initAddressCallBack={(f) => this.props.initPickAddressCallBack(f)} />
                                    </div>
                                    <div className="col">
                                        <AddressEntry ref="child" title="DROP-OFF LOCATION" type={''}
                                            address={address = {
                                                location: _invoiceObj.dropOffLocation, addr1: _invoiceObj.dropOffAddr_Address_Line1,
                                                city: _invoiceObj.dropOffAddr_Address_City, state: longName(_invoiceObj.dropOffAddr_Address_State),
                                                zipCode: _invoiceObj.dropOffAddr_Address_Zip, landmark: _invoiceObj.dropOffAddr_Address_Landmark, latLong: _invoiceObj.drop_Lat_Lang
                                            }}
                                            isDispatch={isManagement() ? false : true}
                                            reCalMileage={(e) => { this.props.reCalMileage(); }}
                                            reviewLocation={this.props.reviewLocation}
                                            allStates={this.props.allStates} onFieldValueChange={(key, value) => {
                                                this.props.onAddressChange('dropOff', key, value)
                                            }} onAddressObjByLocationChange={(addrObj) => {
                                                this.props.onAddressChange('dropOff', '', addrObj);

                                            }}
                                            initAddressCallBack={(f) => this.props.initDropAddressCallBack(f)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </Form>
                { /*this model is used for default account selection */}
                <Modal show={this.state.showAccountDefaultsModal} >
                    <div className="modal-body">
                        <div className="col-md-12 col-sm-12">
                            <label>Load account defaults?</label>
                        </div>
                        <div className="modal-footer" style={{ justifyContent: 'flex-start' }}>
                            <button type="button" className="btn btn-success btn-sm" onClick={(event) => {
                                this.props.fetchReQAccountInfo(this.state.tempAccountId, '', 'regular', false);
                                this.props.serviceCallInfoFieldChange('', 'accountId', this.state.tempAccountId);
                                this.setState({ showAccountDefaultsModal: false })
                            }} >Yes</button>
                            <button type="button" className="btn btn-success btn-sm" onClick={(event) => {
                                this.props.fetchReQAccountInfo(this.state.tempAccountId, '', 'regular', true);
                                this.props.serviceCallInfoFieldChange('', 'accountId', this.state.tempAccountId);
                                this.setState({ showAccountDefaultsModal: false })
                            }}>No</button>
                            <button className="btn btn-danger btn-sm" onClick={(event) => { this.setState({ showAccountDefaultsModal: false }) }}>Cancel</button>
                        </div>
                    </div>
                </Modal>
                <Modal show={this.state.subInvoiceModal} >
                    <div className="modal-body">
                        <div className="col-md-12 col-sm-12">
                            <label>{this.state.textVal} account list</label>
                        </div>
                        <div className="">
                            <ReactSelect name="accountId"
                                closeMenuOnSelect={true}
                                validationArray={[required]}
                                options={accountListByType.map((item, t) => {
                                    return ({ value: item.optionVal, label: item.optionText })
                                })}
                                value={{
                                    value: (this.state.tempAccountId),
                                    label: (accountListByType.find((e) => e.optionVal == this.state.tempAccountId) || {}).optionText
                                }}
                                onChange={(e) => {
                                    this.setState({ tempAccountId: e.value })
                                    this.props.reloadCall();
                                }}
                            />
                        </div>
                        <p hidden={accountListByType.length > 0} class="text-danger">* No Account Available</p>
                        <div className="modal-footer" style={{ justifyContent: 'flex-start' }}>
                            <button type="button" className="btn btn-success btn-sm" onClick={() => {
                                this.props.handleSubInvoice(this.state.textVal, this.state.tempAccountId)
                                this.setState({ subInvoiceModal: false })
                            }
                            }>Proceed</button>
                            <button className="btn btn-danger btn-sm" onClick={(event) => {
                                this.setState({ subInvoiceModal: false })
                            }}>Cancel</button>
                        </div>
                    </div>

                </Modal>
                <Modal show={this.state.openAhrmodel} >
                 <div className="modal-body">
                  <div className="form-group row" style={{ position: 'relative', marginTop: '4rem' }}>
                    <label htmlFor="AHR_Type" className="col-sm-3 form-control-label" style={{ margin: '10px 0px', fontWeight: '600' }}>
                      Select Service Type
                    </label>
                    <div className="col-sm-9">
                      <select
                        id="AHR_Type"
                        className="form-control"
                        value={this.state.ahrType}
                        onChange={this.handleAHRTypeChange}
                      >
                        <option value="propertyRelease">Property Release</option>
                        <option value="vehicleRelease">Vehicle Release</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="Gate_Fee" className="col-sm-3 form-control-label" style={{ margin: '10px 0px', fontWeight: '600' }}>
                      Gate Fee Amount
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="number"
                        id="Gate_Fee"
                        className="form-control"
                        value={this.state.ahrGateFee}
                        onChange={this.handleGateFeeChange}
                        min="0"
                        step="0.01"
                      />
                    </div>
                  </div>
                  <div className="form-group text-center">
                    <input
                      type="button"
                      className="btn btn-success"
                      name="edit"
                      value="Add New Call"
                      disabled={this.state.clickAllowed === false}
                      onClick={this.sessionAhrIInvoice}
                    />{'  '}
                    <button className="btn btn-primary" style={{ width: '80px' }} onClick={(event) => {
                                this.setState({ openAhrmodel: false })
                            }}>
                      Close
                    </button>
                  </div>
                  </div>
                </Modal>
            </div>
        </Fragment>);
    }

}
export default ServiceCallInfo
