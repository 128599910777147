import React, { Component, Fragment } from 'react';
import TmDateTimePicker from '../../utilComponents/tmDateTimePicker/tmDateTimePicker';
import ReactSelect from '../../../components/utilComponents/tmSlimSelect/reactSelect';
import { GlobalValues } from '../../../util/constants';
import { isManagement} from '../../../util/customMethods';
import moment from 'moment';
import Form from 'react-validation/build/form';
import {
    required
} from "../../../util/validationMethods.js";
import {reasonClm} from '../../../util/tableColumnUtil/summaryClms'
import { ExcelFile, ExcelSheet, ExcelColumn } from 'react-data-export';

class SummaryByReasonCtrl extends Component {
    render() {
		return (
            <Fragment>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card form-group">
                                <div className="card-body pd-10">
                                    <Form ref={c => {
                                        this.formSummaryByReasonInfo = c;
                                        this.props.initCallBack(c);
                                    }}>
                                     <div className="row">
                                    <div className="col-12 form-row">
                                    <div className="form-group">
                                            <label htmlFor="from" className="form-label col-form-label mr-2">From</label>
                                        </div>
                                        <div className="col-2 form-group">
                                        <div>
                                        <TmDateTimePicker
                                        className="form-control"
                                        selectedDate={
                                            this.props.fromdate === "" || this.props.fromdate === null
                                                ? null
                                                : moment(
                                                    this.props.fromdate,
                                                    GlobalValues.dateFormat
                                                )._d
                                        }
                                        dateFormat={GlobalValues.dateFormat}
                                        validationArray={[required]}
                                        showYearDropdown={true}
                                        showmonthdropdown={true}
                                        name="fromDate"
                                        reqmsg="From date is required"
                                        onDateSelectChange={e => {
                                            this.props.onSummaryByReasonFieldChange('fromDate', e === null ? '' : moment(e).format(GlobalValues.dateFormat))
                                            this.forceUpdate()
                                        }} 
                                    />
                                    </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="to" className="form-label col-form-label mr-2">To</label>
                                        </div>
                                        <div className="col-2 form-group">
                                        <div>
                                        <TmDateTimePicker
                                        className="form-control"
                                        selectedDate={
                                            this.props.todate === "" || this.props.todate === null
                                                ? null
                                                : moment(
                                                    this.props.todate,
                                                    GlobalValues.dateFormat
                                                )._d
                                        }
                                        dateFormat={GlobalValues.dateFormat}
                                        validationArray={[required]}
                                        showYearDropdown={true}
                                        showmonthdropdown={true}
                                        name="toDate"
                                        reqmsg="To date is required"
                                        onDateSelectChange={e => {
                                            this.props.onSummaryByReasonFieldChange('toDate', e === null ? '' : moment(e).format(GlobalValues.dateFormat))
                                            this.forceUpdate()
                                        }} 
                                    />
                                   
                                    </div>
                                    
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="driverId" className="form-label col-form-label mr-2">Reason</label>
                                        </div>
                                        <div className="col-md-2 form-group">
                                                    <ReactSelect name="idCompany"
                                                    isClearable={true}
                                                        closeMenuOnSelect={true}
                                                        notAmultiSelect={true}
                                                        placeholder="Company"
                                                        options={this.props.callReasons.map((item, t) => {
                                                            return ({ value: item.optionTxt, label: item.optionTxt })
                                                        })}
                                                        value={{
                                                            value: this.props.stateObj.reason || '',
                                                            label: this.props.stateObj.reason || ''
                                                        }}
                                                        onChange={(selectedOption) => {
                                                            this.props.onSummaryByReasonFieldChange('reason', selectedOption&&selectedOption.value||'');
                                                        }}
                                                    />
                                                </div>
                                                {isManagement()?<div className="form-group">
                                            <label htmlFor="driverId" className="form-label col-form-label mr-2">Company</label>
                                        </div>:null}
                                        {isManagement()?
                                    <div className="col-md-3 form-group">
                                    <ReactSelect name="idCompany"
                                                    closeMenuOnSelect={true}
                                                    notAmultiSelect={true}
                                                    placeholder="Company"
                                                    isClearable={true}
                                                    options={this.props.childCompList.map((item, t) => {
                                                        return ({ value: item.optionVal, label: item.optionText })
                                                    })}
                                                    value={{
                                                        value: ((this.props.stateObj.idCompany || '').toString()),
                                                        label: (this.props.childCompList.find((e) => e.optionVal == (this.props.stateObj.idCompany || '').toString()) || {}).optionText
                                                    }}
                                                    onChange={(selectedOption) => {
                                                        this.props.onSummaryByReasonFieldChange('idCompany', parseInt(selectedOption&&selectedOption.value||0));
                                                    }}
                                                />
                                    </div>:null}
                                        </div>
                                        </div>
                                        <div className='row'>
                                        <div className="col-3 form-row"></div>
                                        <div className="col-6 form-row">
                                        <div className="form-group">
                                        <button onClick={this.props.Go} type="button" className="btn btn-success custom-reports-btn mr-2"
                                                >Go</button>
                                                <button type="button" className="btn btn-warning custom-reports-btn mr-2" onClick={this.props.Export}>Export</button>
                                                <button type="button" className="btn btn-primary custom-reports-btn mr-2" onClick={this.props.Email}>Email</button>
                                                {this.props.stateObj.render ?
                                                    <ExcelFile hideElement={true}
                                                        filename={`${"SummaryByReasonReport"}${moment().format('MMDDYYhhmm')}`}
                                                        fileExtension={'xlsx'}
                                                    >
                                                        <ExcelSheet
                                                            data={this.props.data.reasonCodeDetails || []}
                                                            name={'Summary By Reason Report'}
                                                        >
                                                            {(reasonClm || []).map(column => {
                                                                return (
                                                                    <ExcelColumn
                                                                        key={column.accessor}
                                                                        label={column.Header}
                                                                        value={column.accessor}
                                                                    />
                                                                );
                                                            })}
                                                        </ExcelSheet>
                                                    </ExcelFile>
                                                    : null}
                                                <button type="button" className="btn btn-success custom-reports-btn mr-2" onClick={this.props.Print}>Print</button>
                                        </div>
                                        <div className="col-3 form-row"></div>
                                    </div>
                                    </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>       
            </Fragment>
        );
    }
}
export default SummaryByReasonCtrl;