import React, { Component, Fragment } from 'react';
import Form from 'react-validation/build/form';
import {RangeStepInput} from 'react-range-step-input';
import { isManagement } from '../../../util/customMethods';
import ReactSelect from '../../../components/utilComponents/tmSlimSelect/reactSelect';
import moment from 'moment';
import {inventoryColmun} from '../../../util/tableColumnUtil/inventoryColmun';
import { ExcelFile, ExcelSheet, ExcelColumn } from 'react-data-export';

class StorageInventoryCtrl extends Component {
    state = {}
    render() {
        return (
            <Fragment>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card form-group">
                            <div className="card-body pd-10">
                                <Form ref={c => {
                                    this.formStorageInventoryInfo = c;
                                    this.props.initCallBack(c);
                                }}>
                                    <div className="card form-group">
                                    <div className="card-body pd-10">
                                    <div className="form-row">
                                        <div className="col-md-2 input-group">
                                            <div className="custom-control custom-checkbox mb-5 mr-2">
                                                <input className="custom-control-input"
                                                    id="storageInventoryBitween"
                                                    type="checkbox"
                                                    name="isBtwEnabled"
                                                    checked={this.props.inventoryState.isBtwEnabled}
                                                    onChange={(e)=>{
                                                        this.props.onFieldChange('isBtwEnabled',!(this.props.inventoryState.isBtwEnabled))
                                                        if(!(!this.props.inventoryState.isBtwEnabled)){
                                                            this.props.onFieldChange('daysLimitOne',0)
                                                            this.props.onFieldChange('daysLimitTwo',0)
                                                        }
                                                }}
                                                />
                                                <label className="custom-control-label" htmlFor="storageInventoryBitween">BETWEEN</label>
                                            </div>
                                        </div>
                                        <div className="col-md-3 input-group">
                                        <RangeStepInput disabled={!this.props.inventoryState.isBtwEnabled} onChange={(e)=>{this.props.onFieldChange('daysLimitOne',e.target.value)}} min={0} max={100} value={this.props.inventoryState.daysLimitOne} step={1} />
                                        <div className="input-group-append"><span className="input-box ml-2">{this.props.inventoryState.daysLimitOne}</span></div>
                                        </div>
                                        <div className="col-md-1 inout-group">
                                            <label htmlFor="and" className="">AND</label>
                                        </div>
                                        <div className="col-md-3 input-group">
                                        <RangeStepInput disabled={!this.props.inventoryState.isBtwEnabled} onChange={(e)=>{this.props.onFieldChange('daysLimitTwo',e.target.value)}} min={0} max={100} value={this.props.inventoryState.daysLimitTwo} step={1}/>
                                        <div className="input-group-append"><span className="input-box ml-2">{this.props.inventoryState.daysLimitTwo}</span></div>
                                        </div>
                                        <div className="col-md-1 input-group">
                                            <label htmlFor="daysOld" className="">DAYS OLD</label>
                                        </div>
                                        </div>
                                        </div>
                                    </div>
                                    <div className="card form-group">
                                    <div className="card-body pd-10">
                                    {isManagement()?
                                    <div className="form-row">
                                    <div className="col-md-2 form-group">
                                            <label className='text-blue' htmlFor="location" >SELECT COMPANY :</label>
                                    </div>
                                   {isManagement()? <div className="col-md-3 form-group">
                                    <ReactSelect name="idCompany"
                                                    notAmultiSelect={true}
                                                    closeMenuOnSelect={true}
                                                    isClearable={true}
                                                    options={this.props.childCompList.map((item, t) => {
                                                        return ({ value: item.optionVal, label: item.optionText })
                                                    })}
                                                    value={{
                                                        value: ((this.props.inventoryState.idCompany || '').toString()),
                                                        label: (this.props.childCompList.find((e) => e.optionVal == (this.props.inventoryState.idCompany || '').toString()) || {}).optionText
                                                    }}
                                                    onChange={(selectedOption) => {
                                                        this.props.onFieldChange('idCompany', parseInt(selectedOption&&selectedOption.value||0));
                                                    }}
                                                />
                                    </div>:null}
                                    <div className="col-md-2 form-group">
                                            <label className='text-blue' htmlFor="location" > ACCOUNT </label>
                                    </div>
                                    <div className="col-md-3 form-group">
                                    <ReactSelect name="idCompany"
                                                    closeMenuOnSelect={true}
                                                    notAmultiSelect={true}
                                                    isClearable={true}
                                                    options={(this.props.accountList||[]).map((item, t) => {
                                                        return ({ value: item.optionVal, label: item.optionText })
                                                    })}
                                                    value={{
                                                        value: ((this.props.inventoryState.accountId || '').toString()),
                                                        label: ((this.props.accountList||[]).find((e) => e.optionVal == (this.props.inventoryState.accountId || '').toString()) || {}).optionText
                                                    }}
                                                    onChange={(selectedOption) => {
                                                        this.props.onFieldChange('accountId', parseInt(selectedOption&&selectedOption.value||0));
                                                       
                                                    }}
                                                />
                                                
                                    </div>
                                    </div>:<div className="form-row">
                                    <div className="col-md-4 form-group"></div>
                                    <div className="col-md-2 form-group">
                                            <label className='text-blue' htmlFor="location" >SELECT LOCATION :</label>
                                    </div>
                                    <div className="col-md-2 form-group">
                                            <select className="form-control" onChange={(e)=>{this.props.onFieldChange('selectedLocation',e.target.value)}} >
                                                <option value=''>All</option>
                                                {(this.props.locations||[]).map((loc, key) => {
                                                        return (
                                                            <option key={key} value={loc.location}>
                                                                {loc.location}
                                                            </option>
                                                        );
                                                    })}
                                            </select>
                                    </div>
                                    <div className="col-md-4 form-group"></div>
                                    </div>}
                                    <div className="form-row">
                                    <div className="col-md-2 form-group"></div>
                                        <div className="col-md-8 form-group">
                                        <button type="button" style={{ width: '23%' }} className="btn btn-success  mr-2" onClick={this.props.inventoryGo}>Go</button>
                                        <button type="button" style={{ width: '23%' }} className="btn btn-warning  mr-2" onClick={this.props.inventoryExport}>Export</button>
                                        {this.props.inventoryState.render ? 
                                    <ExcelFile hideElement={true}
                                        filename={`${"StorageInventory_"}${moment().format('MMDDYYhhmm')}`}
                                        fileExtension={'xlsx'}
                                    >
                                        <ExcelSheet 
                                            data={this.props.data || []}
                                            name={'Storage Inventory Report'}
                                        >
                                            {(inventoryColmun||[]).map(column => {
                                                return (
                                                    <ExcelColumn
                                                        key={column.accessor}
                                                        label={column.Header}
                                                        value={column.accessor}
                                                    />
                                                    );
                                                })}
                                        </ExcelSheet>
                                    </ExcelFile>
                                : null}
                                        <button type="button" style={{ width: '23%' }} className="btn btn-primary  mr-2" onClick={this.props.emailInventory}>Email</button>
                                        <button type="button" style={{ width: '23%' }} className="btn btn-success  mr-2" onClick={this.props.inventoryPrint}>Print</button>
                                        </div>
                                        <div className="col-md-2 form-group"></div>
                                    </div>
                                    </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}
export default StorageInventoryCtrl;